import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TPartsMaintenanceGroupIdentifiers =
  | 'groupPartsDisclaimer'
  | 'groupPartsDiscounts'
  | 'groupPartsFees'
  | 'groupPartsInventory'
  | 'groupPartsOrderSources'
  | 'groupPartsOrderVendors'
  | 'groupPartsPriceCodes';

export type TPartsMaintenanceDataKeys =
  | 'partsDisclaimer'
  | 'useDefaultPartsDisclaimer'
  | 'partsDiscounts'
  | 'partsFees'
  | 'partsInventory'
  | 'partsOrderSources'
  | 'partsOrderVendors'
  | 'partsPriceCodes'
  | 'usesAutoReplenish'
  | 'autoReplenishVendor'
  | 'extendedWarrantyPricing'
  | 'warrantyPricing';

const partsMaintenanceGroupMap = {
  moduleType: 'parts',
  submoduleType: 'maintenance',
  discriminator: 'parts-maintenance',
  groups: {
    groupPartsDisclaimer: ['useDefaultPartsDisclaimer', 'partsDisclaimer'],
    groupPartsDiscounts: ['partsDiscounts'],
    groupPartsFees: ['partsFees'],
    groupPartsInventory: ['partsInventory'],

    groupPartsOrderSources: [
      'partsOrderSources',
      'usesAutoReplenish',
      'autoReplenishVendor',
    ],
    groupPartsOrderVendors: ['partsOrderVendors'],
    groupPartsPriceCodes: [
      'partsPriceCodes',
      'extendedWarrantyPricing',
      'warrantyPricing',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TPartsMaintenanceGroupIdentifiers,
  TPartsMaintenanceDataKeys
>;

export type TPartsMaintenanceGroupMap = typeof partsMaintenanceGroupMap;

export class PartsMaintenanceData
  implements TDataDefinition<TPartsMaintenanceDataKeys>
{
  partsDisclaimer = new DataInitializerBasic<IFileUploadReference[]>([]);
  useDefaultPartsDisclaimer = new DataInitializerBasic<boolean>();
  partsDiscounts = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsFees = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsInventory = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsOrderSources = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsOrderVendors = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsPriceCodes = new DataInitializerBasic<IFileUploadReference[]>([]);
  usesAutoReplenish = new DataInitializerBasic<boolean>();
  autoReplenishVendor = new DataInitializerBasic<string>();
  extendedWarrantyPricing = new DataInitializerBasic<string>();
  warrantyPricing = new DataInitializerBasic<string>();
}

export const partsMaintenanceStructure: Structures['parts-maintenance'] = {
  moduleType: 'parts',
  submoduleType: 'maintenance',
  discriminator: 'parts-maintenance',
  groups: {
    groupPartsDisclaimer: {
      groupLabel: 'Invoice Disclaimer',
      groupUrl: 'groupPartsDisclaimer',
      groupType: 'standard',
      questions: {
        useDefaultPartsDisclaimer: {
          questionKey: 'useDefaultPartsDisclaimer',
          label: 'Use Default Parts Invoice Disclaimer',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Do you want to use VUE's default parts invoice disclaimer?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: "VUE's default parts invoice disclaimer is:\n\nAny warranties on the products sold hereby are those made by the manufacturer. The seller hereby expressly disclaims all warranties, either expressed or implied, including any warranty of merchantability or fitness for a particular purpose, and neither assumes nor authorizes any other person to assume for it any liability in connection with the said sale of said products.\n    \u2022 ALL RETURNS MUST BE MADE WITHIN 10 DAYS AND\n       SUBJECT TO 20% HANDLING CHARGE.\n    \u2022 NO REFUNDS ON ELECTRICAL PARTS OR SPECIAL\n       ORDER PART.\n    \u2022 ALL RETURNED PARTS MUST BE IN ORIGINAL\n       SALEABLE PACKAGING.",
            },
          ],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['partsDisclaimer'],
            },
          ],
        },
        partsDisclaimer: {
          questionKey: 'partsDisclaimer',
          label: 'Parts Invoice Disclaimer',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide the disclaimer that should print on your parts invoices.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsDiscounts: {
      groupLabel: 'Common Discounts',
      groupUrl: 'groupPartsDiscounts',
      groupType: 'standard',
      questions: {
        partsDiscounts: {
          questionKey: 'partsDiscounts',
          label: 'Common Discounts',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of some of the most common discounts that you apply to your parts invoices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each discount, please provide:\n    \u2022  the code\n    \u2022  name/description\n    \u2022  whether the discount is shared with the service department',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsFees: {
      groupLabel: 'Common Fees',
      groupUrl: 'groupPartsFees',
      groupType: 'standard',
      questions: {
        partsFees: {
          questionKey: 'partsFees',
          label: 'Common Fees',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of some of the most common fees that you apply to your parts invoices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each fee, please provide:\n    \u2022 the code\n    \u2022 name/description\n    \u2022 whether the fee is shared with service',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsInventory: {
      groupLabel: 'Current Inventory',
      groupUrl: 'groupPartsInventory',
      groupType: 'standard',
      questions: {
        partsInventory: {
          questionKey: 'partsInventory',
          label: 'Current Inventory',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a parts inventory report from your retiring DMS with as much detail as possible.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },

    groupPartsOrderSources: {
      groupLabel: 'Order Sources',
      groupUrl: 'groupPartsOrderSources',
      groupType: 'standard',
      questions: {
        partsOrderSources: {
          questionKey: 'partsOrderSources',
          label: 'Order Sources',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your parts order sources.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This list is used to ensure that your data is properly converted from your retiring DMS to VUE.\n\nFor each order source, please provide:\n',
            },
            {
              attributes: null,
              text: '    \u2022 the source name\n    \u2022 manufacturer\n    \u2022 price code\n    \u2022 description\n    \u2022 OEM class code\n    \u2022 part type\n    \u2022 minimum days supply\n    \u2022 maximum days supply\n    \u2022 rounding factor\n    \u2022 search period\n    \u2022 include lost sales\n    \u2022 phase in demand\n    \u2022 phase in months\n    \u2022 phase in hits\n    \u2022 phase out demand\n    \u2022 phase out months',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        usesAutoReplenish: {
          questionKey: 'usesAutoReplenish',
          label: 'Uses Auto Replenishment',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you use an auto-replenishment program OEM or third party for parts orders?',
            },
          ],
          subprompt: [],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['autoReplenishVendor'],
            },
          ],
        },
        autoReplenishVendor: {
          questionKey: 'autoReplenishVendor',
          label: 'Auto Replenishment Vendor',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide the name of the OEM or third-party program that you use for auto-replenishing your parts orders. Example: Partseye or Retail Inventory Management (RIM).',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsOrderVendors: {
      groupLabel: 'Order Vendors',
      groupUrl: 'groupPartsOrderVendors',
      groupType: 'standard',
      questions: {
        partsOrderVendors: {
          questionKey: 'partsOrderVendors',
          label: 'Order Vendors',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of any vendor, including your OEMs, that you currently order parts from.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each vendor, please provide:\n    \u2022 code\n    \u2022 name\n    \u2022 address\n    \u2022 phone number',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsPriceCodes: {
      groupLabel: 'Price Codes',
      groupUrl: 'groupPartsPriceCodes',
      groupType: 'standard',
      questions: {
        warrantyPricing: {
          questionKey: 'warrantyPricing',
          label: 'Warranty Pricing',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'What pricing do you use to bill warranty parts on a repair order?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        extendedWarrantyPricing: {
          questionKey: 'extendedWarrantyPricing',
          label: 'Extended Warranty Pricing',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'What pricing do you use to bill ',
            },
            {
              attributes: { bold: true },
              text: 'extended warranty parts ',
            },
            {
              attributes: null,
              text: 'on a repair order?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        partsPriceCodes: {
          questionKey: 'partsPriceCodes',
          label: 'Price Codes',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your parts price codes.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each price code, please provide:\n    \u2022 the code\n    \u2022 the description\n    \u2022 the sale source basis (cost, list, wholesale, matrix)\n    \u2022 the calculation, if not defined in the description\n       (Example: List - 20%)\n    \u2022 if applicable, the matrix pricing table with markup calculations',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
};
