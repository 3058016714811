<div class="mx-auto w-full max-w-lg">
  <ul role="list" class="grid grid-cols-2 gap-1">
    @for (option of options; track option.value) {
      <li>
        <button
          dominionDiscoveryBtn
          (click)="selectResponse(option.value)"
          [attr.aria-selected]="data.value === option.value"
          class="w-full"
        >
          {{ option.label }}
        </button>
      </li>
    }
  </ul>

  @if (data && data.log.length > 0) {
    <div class="mt-8">
      <dominion-response-history-trigger
        [log]="data.log"
        [options]="options"
      ></dominion-response-history-trigger>
    </div>
  }
</div>
