import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ResponseInput } from '../input.interface';
import {
  IDiscoveryDataProperty,
  ResponseOptions,
  ResponseValidation,
} from '@dominion/interfaces';
import { ValidationService } from '../../services/input-validation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { InSituSelectComponent } from '../../shared/inputs/in-situ-select/in-situ-select.component';
import { ResponseHistoryTriggerComponent } from '../../response-history/response-history-trigger.component';
import { SharedModule } from '../../shared/shared.module';
import { AddInstanceButtonComponent } from '../shared-controls/add-instance-button.component';
import { RemoveInstanceButtonComponent } from '../shared-controls/remove-instance-button.component';
import { QuestionComponentConfig } from 'libs/interfaces/src/lib/modules/core/question-component-config.interfaces';

@Component({
  selector: 'dominion-input-mapping',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DiscoveryNextBtnDirective,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    InSituSelectComponent,
    ResponseHistoryTriggerComponent,
    AddInstanceButtonComponent,
    RemoveInstanceButtonComponent,
  ],
  templateUrl: './input-mapping.component.html',
})
export class InputMappingComponent implements OnChanges, ResponseInput {
  @ViewChildren(PopoverHostDirective)
  popoverHosts: QueryList<PopoverHostDirective>;

  @Input({ required: true }) data: IDiscoveryDataProperty<
    { lhs: string; rhs: string }[]
  >;
  @Input({ required: true }) options: ResponseOptions[];
  @Input() componentConfig?: QuestionComponentConfig;
  @Input() validation?: ResponseValidation[] = [];
  @Input() limit? = 1;

  @Output() response = new EventEmitter<{ lhs: string; rhs: string }[]>();

  form: FormGroup;
  errorMessage?: string;
  serverErrMsg?: string;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
  ) {
    this.form = this.fb.group({
      instances: this.fb.array([]),
    });
  }

  get instances() {
    return this.form.get('instances') as FormArray;
  }

  get canAddInstance(): boolean {
    return !!(
      this.limit &&
      this.instances.length < this.limit &&
      this.instances.controls[this.instances.length - 1]?.valid
    );
  }

  get canRemoveInstances(): boolean {
    return this.instances.length > 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue !== changes['data']?.previousValue) {
      this.setInitialValues();
    }
    if (changes['validation'] && this.validation) {
      this.applyValidators();
    }
  }

  setInitialValues() {
    if (
      this.limit &&
      Array.isArray(this.data.value) &&
      this.data.value.length > this.limit
    ) {
      this.data.value = this.data.value.slice(0, this.limit);
    }

    this.instances.clear();

    if (Array.isArray(this.data.value)) {
      this.data.value.forEach((value) => this.addInstance(value));
    } else if (this.data.value) {
      this.addInstance(this.data.value);
    } else {
      this.addInstance({ lhs: '', rhs: '' });
    }
  }

  addInstance(value = { lhs: '', rhs: '' }) {
    const control = this.fb.group({
      lhs: [value.lhs ?? '', Validators.required],
      rhs: [value.rhs ?? '', Validators.required],
    });
    control
      .get('lhs')
      ?.setValidators(
        this.validationService.getComposedValidator(this.validation ?? []),
      );
    this.instances.push(control);
  }

  removeInstance(index: number) {
    if (this.instances.length > 1) {
      this.instances.removeAt(index);
    }
  }

  applyValidators() {
    this.instances.controls.forEach((control) => {
      control
        .get('lhs')
        ?.setValidators(
          this.validationService.getComposedValidator(this.validation ?? []),
        );
      control.get('lhs')?.updateValueAndValidity();
    });
  }

  save() {
    this.resetErrors();
    if (this.form.valid) {
      this.response.emit(this.instances.value);
    } else {
      this.errorMessage = 'Please enter a valid response.';
      this.showPopovers();
    }
  }

  checkErrors() {
    if (this.form.invalid) {
      this.errorMessage = 'Please enter a valid response.';
      this.showPopovers();
    }
  }

  handleSuccess(): void {
    return;
  }

  handleErr(err: HttpErrorResponse) {
    this.serverErrMsg = err.error.message;
    this.showPopovers();
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.errorMessage = undefined;
    this.hidePopovers();
  }

  private showPopovers() {
    this.instances.controls.forEach((control, index) => {
      const lhsPopover = this.popoverHosts.toArray()[index * 2];
      const rhsPopover = this.popoverHosts.toArray()[index * 2 + 1];

      if (control.get('lhs')?.invalid) {
        lhsPopover?.show();
      } else if (control.get('rhs')?.invalid) {
        // Only show the rhs error if lhs is valid
        rhsPopover?.show();
      }
    });
  }

  private hidePopovers() {
    this.popoverHosts.forEach((popoverHost) => popoverHost.hide());
  }
}
