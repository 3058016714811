export function getScopesString(): string {
  let scopes = '';
  Object.entries(secureVueEndpoints).forEach(([version, controllers]) => {
    controllers.forEach((controller) => {
      scopes += `${controller}.${version} `;
    });
  });
  return scopes;
}

export const secureVueEndpoints = {
  'setups.1.0': [
    'GetGLMasterSetups',
    'GetJournals',
    'GetScheduleSetups',
    'GetPartsAccountCodes',
    'GetServiceAccountCodes',
    'GetPartsPriceCodes',
  ],
  'V.1.0': ['lenders'],
} as const;

export type SecureVueEndpoints = typeof secureVueEndpoints;
export type SecureVueVersions = keyof SecureVueEndpoints;
export type SecureVueControllers =
  SecureVueEndpoints[keyof SecureVueEndpoints][number];

export type Scopes<T extends keyof SecureVueEndpoints> =
  `${SecureVueEndpoints[T][number]}.${T}`;
