import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { IDiscoveryDataProperty, ResponseOptions } from '@dominion/interfaces';
import { DiscoveryBtnDirective } from '../../shared/discovery-btn/discovery-btn.directive';
import { ResponseHistoryTriggerComponent } from '../../response-history/response-history-trigger.component';
import { SharedModule } from '../../shared/shared.module';

// Notes:
// input-select could be simpler, >4 items automatic dropdown
// input-select-long would never be a dropdown

@Component({
  selector: 'dominion-input-select-list',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    DiscoveryBtnDirective,
    ResponseHistoryTriggerComponent,
  ],
  templateUrl: './input-select-list.component.html',
})
export class InputSelectListComponent implements ResponseInput {
  @Input({ required: true }) data: IDiscoveryDataProperty<unknown>;
  @Input({ required: true }) options: ResponseOptions[];

  @Output() response: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor() {}

  initializeSelection() {}

  handleErr(err: string): void {
    throw new Error(err);
  }

  handleSuccess(): void {
    return;
  }

  selectResponse(value: unknown): void {
    this.response.emit(value);
  }
}
