import {
  AuthorizationStrategy,
  CompanyAuthRole,
  DominionAuthRole,
  IUserAuthorizations,
} from '@dominion/interfaces';
import {} from './authorization';
import {
  AuthorizationContext,
  ResourceAuthStrategy,
} from './authorization.interfaces';
import { SystemActions, systemAuthorizations } from './authorizations';
import {
  CompanyAdminStrategy,
  CompanyDeptManagerStrategy,
  CompanySuperStrategy,
  DominionAdminStrategy,
  DominionAnyStrategy,
  DominionDeploymentManagerStrategy,
  DominionDeploymentTechnicianStrategy,
  DominionProjectManagerStrategy,
  DominionSuperStrategy,
  DominionViewerStrategy,
} from './authorization.strategies';

export function getAuthorizationStrategies(
  action: SystemActions,
  context: AuthorizationContext,
): AuthorizationStrategy {
  if (systemAuthorizations[action] && systemAuthorizations[action].length > 0) {
    const strategies: AuthorizationStrategy = [];
    const rawStrategies = systemAuthorizations[action];
    for (const strategy of rawStrategies) {
      switch (strategy.type) {
        case 'dominion:admin':
          strategies.push(new DominionAdminStrategy());
          break;
        case 'dominion:projectmanager':
          strategies.push(new DominionProjectManagerStrategy());
          break;
        case 'dominion:any':
          strategies.push(new DominionAnyStrategy());
          break;
        case 'company:any':
          if (context.companyId) {
            strategies.push(new CompanyDeptManagerStrategy(context.companyId));
          }
          break;
        case 'dominion:user':
          if (context.targetUserAuthorizations) {
            strategies.push(
              ...buildDominionUserStrategy(
                context.targetUserAuthorizations,
                strategy.context,
              ),
            );
          }
          break;
        case 'company:user':
          if (context.targetUserAuthorizations) {
            strategies.push(
              ...buildCompanyUserStrategy(
                context.targetUserAuthorizations,
                context,
                strategy.context,
              ),
            );
          }
          break;
      }
    }
    return strategies;
  }
  return [];
}

function buildDominionUserStrategy(
  targetAuth: IUserAuthorizations,
  context: ResourceAuthStrategy<'dominion'>['context'],
): AuthorizationStrategy {
  if (context && 'dominion:' in targetAuth) {
    const targetRole = targetAuth['dominion:'].roles[0] as DominionAuthRole;
    const requiredRole = context[targetRole];
    switch (requiredRole) {
      case 'super':
        return [new DominionSuperStrategy()];
      case 'admin':
        return [new DominionAdminStrategy()];
      case 'projectmanager':
        return [new DominionProjectManagerStrategy()];
      case 'deploymentmanager':
        return [new DominionDeploymentManagerStrategy()];
      case 'deploymenttechnician':
        return [new DominionDeploymentTechnicianStrategy()];
      case 'viewer':
        return [new DominionViewerStrategy()];
      case null:
        return [];
      default:
        return [];
    }
  }
  return [];
}

function buildCompanyUserStrategy(
  targetAuth: IUserAuthorizations,
  authContext: AuthorizationContext,
  strategyContext: ResourceAuthStrategy<'company'>['context'],
): AuthorizationStrategy {
  if (authContext.companyId && strategyContext) {
    if (`company:${authContext.companyId}` in targetAuth) {
      const targetRole = targetAuth[`company:${authContext.companyId}`]
        .roles[0] as CompanyAuthRole;
      const requiredRole = strategyContext[targetRole];
      switch (requiredRole) {
        case 'super':
          return [new CompanySuperStrategy(authContext.companyId)];
        case 'admin':
          return [new CompanyAdminStrategy(authContext.companyId)];
        case 'manager':
          return [new CompanyDeptManagerStrategy(authContext.companyId)];
        case null:
          return [];
        default:
          return [];
      }
    }
  }
  return [];
}
