import {
  ICoreModule,
  ICoreSubmoduleReference,
  ModuleType,
} from '../modules/core/module/core-module.interfaces';
import { FakeDocumentCore } from './fake-doc-core';

export class FakeModule extends FakeDocumentCore implements ICoreModule {
  _id: string = 'm1';
  name: string = 'Fake Module';
  moduleType: ModuleType = 'accounting';
  desc: string = '';
  dateCreated: Date = new Date('2025-01-01');
  createdBy: string = '';
  externalAssigned: string[] = [];
  submodules: ICoreSubmoduleReference[] = [
    {
      submoduleId: 's1',
      discriminator: 'accounting-preliminary',
      isAvailable: false,
      dueDate: null,
      completion: {
        total: 100,
        complete: 0,
        groups: {
          group1: {
            total: 50,
            complete: 0,
          },
        },
      },
    },
    {
      submoduleId: 's2',
      discriminator: 'accounting-maintenance',
      isAvailable: false,
      dueDate: null,
      completion: {
        total: 100,
        complete: 0,
        groups: {
          group1: {
            total: 50,
            complete: 0,
          },
        },
      },
    },
    {
      submoduleId: 's3',
      discriminator: 'accounting-preliminary',
      isAvailable: false,
      dueDate: null,
      completion: {
        total: 100,
        complete: 0,
        groups: {
          group1: {
            total: 50,
            complete: 0,
          },
        },
      },
    },
  ];
  constructor(initial: Partial<ICoreModule> = {}) {
    super();
    const clone = JSON.parse(JSON.stringify(initial));
    Object.assign(this, clone);
  }
}
