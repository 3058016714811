/*
  DOMINION STRATEGIES
*/

import { IAuthorization } from '@dominion/interfaces';

export class DominionSuperStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super'],
        permissions: [],
      },
    ];
  }
}

export class DominionAdminStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
    ];
  }
}

export class DominionProjectManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin', 'projectmanager'],
        permissions: [],
      },
    ];
  }
}

export class DominionDeploymentManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin', 'projectmanager', 'deploymentmanager'],
        permissions: [],
      },
    ];
  }
}

export class DominionDeploymentTechnicianStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: [
          'super',
          'admin',
          'projectmanager',
          'deploymentmanager',
          'deploymenttechnician',
        ],
        permissions: [],
      },
    ];
  }
}

export class DominionViewerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: [
          'super',
          'admin',
          'projectmanager',
          'deploymentmanager',
          'deploymenttechnician',
          'viewer',
        ],
        permissions: [],
      },
    ];
  }
}

export class DominionAnyStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: [
          'super',
          'admin',
          'projectmanager',
          'deploymentmanager',
          'deploymenttechnician',
          'viewer',
        ],
        permissions: [],
      },
    ];
  }
}

// only allows if you are pm on this project or higher rank
export class LocalProjectManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'company',
        id: companyId,
        roles: ['projectmanager'],
        permissions: [],
      },
    ];
  }
}

// only allows if you are the dm on this project, pm on this project, or higher
export class LocalDeploymentManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'company',
        id: companyId,
        roles: ['projectmanager', 'deploymentmanager'],
        permissions: [],
      },
    ];
  }
}

// only allows if you are the dt on this project, dm on this project, pm on this project, or higher
export class LocalDeploymentTechnicianStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'company',
        id: companyId,
        roles: ['projectmanager', 'deploymentmanager', 'deploymenttechnician'],
        permissions: [],
      },
    ];
  }
}

/*
  COMPANY STRATEGIES
*/

export class CompanySuperStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super'],
        permissions: [],
      },
    ];
  }
}

export class CompanyAdminStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super', 'admin'],
        permissions: [],
      },
    ];
  }
}

export class CompanyDeptManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super', 'admin', 'manager'],
        permissions: [],
      },
    ];
  }
}

export class LocalCompanyDeptManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string, moduleId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'module',
        id: moduleId,
        roles: ['manager'],
        permissions: [],
      },
    ];
  }
}
