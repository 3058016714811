<ol role="list" class="max-w-xl antialiased">
  @for (entry of filteredEntries; track entry.date) {
    <li
      class="-mx-4 flex cursor-default flex-col gap-1.5 rounded-sm border-b px-4 py-3 transition first:pt-0 last:border-b-0 last:pb-0"
    >
      <div
        class="flex items-center gap-1.5 text-sm"
        [ngClass]="$first ? '' : 'opacity-60'"
      >
        <div
          class="flex h-3.5 w-3.5 shrink-0 items-center justify-center rounded-sm text-xs font-medium uppercase text-white"
          [ngClass]="
            entry.userType === 'internal' ? 'bg-dms-teal' : 'bg-dms-light-green'
          "
        >
          {{ entry.firstName[0] }}
        </div>

        <p class="flex-1 truncate font-medium">
          <strong
            [title]="entry.firstName + ' ' + entry.lastName"
            [ngClass]="
              entry.userType === 'internal' ? 'text-dms-teal' : 'text-dms-green'
            "
            >{{ entry.firstName }} {{ entry.lastName }}</strong
          >
          set response
        </p>

        <time
          [title]="entry.date | date: 'full'"
          datetime="entry.date | date: 'YYYY-MM-dd HH:MM:SS '"
          class="text-xs font-medium text-gray-500"
        >
          {{ entry.date | date: 'h:mm a, M/dd/yy' }}
        </time>
      </div>

      @if (entry.formattedValue) {
        <div
          [title]="entry.formattedValue"
          class="ml-[18px] line-clamp-4 max-w-full rounded-sm p-1 text-sm font-medium"
          [ngClass]="
            $first
              ? 'bg-dms-light-green/20 border-dms-light-green text-dms-green border'
              : 'border border-gray-200 bg-gray-50 text-gray-500'
          "
        >
          {{ entry.formattedValue }}
        </div>
      }
    </li>
  } @empty {
    <p class="py-3 text-sm font-medium italic text-gray-600">
      No response history
    </p>
  }
</ol>
