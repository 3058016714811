import {
  ComparisonMethod,
  IDiscoveryDataProperty,
  PromptText,
  QuestionComponentType,
  QuestionSections,
  QuestionRequirementType,
  ResponseValidation,
  TAllGroupKeys,
  IDiscoveryExport,
  stringifyDiscoveryResponse,
} from '@dominion/interfaces';
import { QuestionComponentConfig } from '../question-component-config.interfaces';

export interface ISubmoduleGroupCompletionStatus {
  identifier: TAllGroupKeys;
  complete: number; // number of completed keys
  total: number; // total number of required keys based on dependency analysis
}

//
//
//
//
//

export type TGenericQStruct<DataKeys> = {
  questionKey: DataKeys;
  label: string;
  prompt: PromptText[];
  subprompt: PromptText[];
  componentType: QuestionComponentType;
  componentLimit?: number;
  inputPrefix?: string;
  inputSuffix?: string;
  componentConfig?: QuestionComponentConfig;
  requirement: QuestionRequirementType;
  allowSkip: boolean;
  order?: number;
  sections?: QuestionSections;
  options: Array<{
    label: string;
    value: any;
  }>;
  validation: ResponseValidation[];
  dependencies: Array<{
    comparisonMethod: ComparisonMethod;
    comparisonValue: any;
    dependentKeys: DataKeys[];
  }>;
};

export class SubmoduleGroup<
  GroupKey extends TAllGroupKeys,
  DataKeys extends string,
> {
  label: string;
  data: Record<DataKeys, IDiscoveryDataProperty<any>>;
  structure: Record<DataKeys, TGenericQStruct<DataKeys>>;
  identifier: GroupKey;
  totalCounter: number = 0;
  requiredKeys: Set<DataKeys> = new Set();

  constructor(
    identifier: GroupKey,
    label: string,
    data: Record<DataKeys, IDiscoveryDataProperty<any>>,
    structure: Record<DataKeys, TGenericQStruct<DataKeys>>,
  ) {
    this.data = data;
    this.label = label;
    this.structure = structure;
    this.identifier = identifier;
  }

  /**
   * Gets the completion status of the submodule group
   *
   * @returns The completion status of the submodule group
   */
  public getCompletion(): ISubmoduleGroupCompletionStatus {
    // check for all or nothing completion first
    // if partial completion, then proceed to dependency analysis
    // log the data and the structure
    const basicCompletion = this.getBasicCompletion();
    if (basicCompletion !== null) {
      return basicCompletion;
    }
    this.getRequiredKeys();
    return this.getCompletionFromRequiredKeys();
  }

  // this checks the easy cases where all questions are complete or all questions are incomplete (i.e., dependencies don't matter)
  getBasicCompletion(): ISubmoduleGroupCompletionStatus | null {
    let completeCounter = 0;
    for (const key in this.data) {
      if (this.data[key]['value'] !== null) {
        if (Array.isArray(this.data[key]['value'])) {
          if (this.data[key]['value'].length > 0) {
            completeCounter++;
          }
        } else {
          completeCounter++;
        }
      }
      this.totalCounter++;
    }
    if (completeCounter === 0) {
      return {
        identifier: this.identifier,
        complete: 0,
        total: this.totalCounter,
      };
    }
    if (completeCounter === this.totalCounter) {
      return {
        identifier: this.identifier,
        complete: this.totalCounter,
        total: this.totalCounter,
      };
    }
    return null;
  }

  // some discovery questions have logical interdependencies
  // these interdependencies are defined by the structure property
  // this function uses the structure property and already-provided responses from the data property to determine which keys in this group are required to be answered
  getRequiredKeys() {
    for (const questionKey in this.structure) {
      let question = this.structure[questionKey];
      if (question.requirement === 'required') {
        this.requiredKeys.add(questionKey);
      }
      for (const dependency of question.dependencies) {
        if (dependency.comparisonMethod === 'non-null') {
          if (this.data[questionKey]['value'] !== null) {
            dependency.dependentKeys.forEach((key) =>
              this.requiredKeys.add(key),
            ); // add all of the dependent keys
            this.requiredKeys.add(questionKey); // add the parent question key
          }
        }
        if (dependency.comparisonMethod === 'equals') {
          if (
            this.data[questionKey as keyof typeof this.data]['value'] ===
            dependency.comparisonValue
          ) {
            dependency.dependentKeys.forEach(
              (key) => this.requiredKeys.add(key), // add the dependent keys
            );
            this.requiredKeys.add(questionKey); // add the parent question key
          }
        }
      }
    }
  }

  // determine completion status based on the required keys set
  getCompletionFromRequiredKeys() {
    let complete = 0;
    for (const key of this.requiredKeys) {
      if (this.data[key]['value'] !== null) {
        if (Array.isArray(this.data[key]['value'])) {
          if (this.data[key]['value'].length > 0) {
            complete++;
          }
        } else {
          complete++;
        }
      }
    }
    return {
      identifier: this.identifier,
      complete,
      total: this.requiredKeys.size,
    };
  }

  /**
   * Generates the human-oriented export object for the submodule group
   *
   * @returns The export object for the submodule group
   */
  getExport(): Omit<
    IDiscoveryExport,
    'moduleType' | 'moduleName' | 'phase' | 'submoduleId'
  >[] {
    // get the required keys for marking required questions
    this.getRequiredKeys();
    const questions = Object.values(
      this.structure,
    ) as TGenericQStruct<DataKeys>[];
    return questions.map((question) => {
      const required = this.requiredKeys.has(question.questionKey);
      const lastLog = this.data[question.questionKey].log[0];
      return {
        groupLabel: this.label,
        groupId: this.identifier,
        questionLabel: question.label,
        questionKey: question.questionKey,
        required: required ? 'true' : 'false',
        prompt: question.prompt
          .map((prompt) => prompt.text)
          .reduce((acc, curr) => acc + curr),
        response: stringifyDiscoveryResponse(
          question.componentType,
          question.options,
          this.data[question.questionKey]?.value,
        ),
        lastEdited: lastLog ? lastLog.date.toDateString() : '---',
        lastEditedBy: lastLog
          ? lastLog.firstName + ' ' + lastLog.lastName
          : '---',
      };
    });
  }
}
