import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ModuleType,
  TGenericGroup,
  TGenericQStruct,
  TSubmoduleDiscriminators,
} from '@dominion/interfaces';
import { CommonModule } from '@angular/common';
import { QuestionPipe } from '../shared/pipes/question.pipe';
import { PromptPipe } from '../shared/pipes/prompt.pipe';
import {
  NO_FILE_UPLOADED_TEXT,
  QuestionDataPipe,
} from '../shared/pipes/question-data.pipe';
import { SharedModule } from '../shared/shared.module';
import { ModuleTypePipe } from '../shared/pipes/module-type.pipe';
import { SubmodulePhasePipe } from '../shared/pipes/submodule-phase.pipe';
import { FormsModule } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { IconCloseComponent } from '../icons/icon-close.component';
import { IconChevronDownComponent } from '../icons/icon-chevron-down.component';
import { IconChevronRightComponent } from '../icons/icon-chevron-right.component';
import { IconChevronsDownUpComponent } from '../icons/icon-chevrons-down-up.component';
import { IconChevronsUpDownComponent } from '../icons/icon-chevrons-up-down.component';

export const SLIDE_ANIMATION_MS = 200;

export interface Question extends TGenericQStruct<string> {}
export interface EnrichedQuestion extends Question {
  promptText: string;
  responseText?: string;
}
export interface QuestionGroup extends TGenericGroup<string> {
  questions: { [key: string]: Question };
}
export interface FilteredQuestionGroup
  extends Omit<QuestionGroup, 'questions'> {
  questions: EnrichedQuestion[];
}
export interface SummaryData {
  groups: QuestionGroup[];
  moduleType?: ModuleType;
  submodulePhase?: TSubmoduleDiscriminators;
  submoduleData: any;
}

@Component({
  selector: 'dominion-discovery-summary',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ModuleTypePipe,
    SubmodulePhasePipe,
    PromptPipe,
    QuestionDataPipe,
    IconCloseComponent,
    IconChevronDownComponent,
    IconChevronRightComponent,
    IconChevronsDownUpComponent,
    IconChevronsUpDownComponent,
  ],
  animations: [
    trigger('slideOver', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate(
          `${SLIDE_ANIMATION_MS}ms ease-in-out`,
          style({ transform: 'translateX(100%)' }),
        ),
      ]),
    ]),
  ],
  providers: [PromptPipe, QuestionPipe, QuestionDataPipe],
  templateUrl: './discovery-summary.component.html',
})
export class DiscoverySummaryComponent implements OnChanges {
  @Input() summaryData: SummaryData;
  @Input() isInternalUser = false;

  @ViewChild('dialog') dialogEl: ElementRef<HTMLDialogElement>;

  searchQuery = '';
  dialogOpen = false;
  showEmptyResponses = true;
  groupsExpanded = true;
  filteredGroups: FilteredQuestionGroup[] = [];

  constructor(
    private promptPipe: PromptPipe,
    private questionPipe: QuestionPipe,
    private questionDataPipe: QuestionDataPipe,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['summaryData'] && !changes['summaryData'].firstChange) {
      this.updateFilteredGroups();
    }
  }

  updateFilteredGroups() {
    if (!this.summaryData?.groups?.length) {
      this.filteredGroups = [];
      return;
    }

    try {
      this.filteredGroups = this.summaryData.groups
        .map((group) => {
          const questions = this.questionPipe.transform(group.questions);
          if (!questions?.length) {
            return null;
          }

          return {
            ...group,
            questions: this.getFilteredQuestions(questions),
          };
        })
        .filter(
          (group): group is FilteredQuestionGroup =>
            group !== null && group.questions.length > 0,
        );
    } catch (error) {
      console.error('Error filtering questions:', error);
      this.filteredGroups = [];
    }
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query;
    this.updateFilteredGroups();
  }

  setShowEmptyResponses(show: boolean): void {
    this.showEmptyResponses = show;
    this.updateFilteredGroups();
  }

  resetFilters(): void {
    this.searchQuery = '';
    this.showEmptyResponses = true;
    this.updateFilteredGroups();
  }

  open(): void {
    this.searchQuery = '';
    this.showEmptyResponses = true;
    this.groupsExpanded = true;
    this.dialogOpen = true;
    this.dialogEl.nativeElement.showModal();
  }

  close(): void {
    this.dialogOpen = false;
    setTimeout(() => {
      this.dialogEl.nativeElement.close();
    }, SLIDE_ANIMATION_MS);
  }

  toggleAllGroups(): void {
    this.groupsExpanded = !this.groupsExpanded;
    requestAnimationFrame(() => {
      document
        .querySelectorAll<HTMLDetailsElement>('details')
        .forEach((detail) => (detail.open = this.groupsExpanded));
    });
  }

  private getFilteredQuestions(questions: Question[]): EnrichedQuestion[] {
    const query = this.searchQuery.toLowerCase();

    return questions
      .map((question) => {
        const response =
          this.questionDataPipe.transform(
            question,
            this.summaryData.submoduleData,
          ) ?? '';

        const responseText = response === NO_FILE_UPLOADED_TEXT ? '' : response;

        return {
          ...question,
          promptText: this.promptPipe.transform(question.prompt) ?? '',
          responseText,
        };
      })
      .filter((question) => {
        if (
          !question.responseText &&
          (!this.isInternalUser || !this.showEmptyResponses)
        ) {
          return false;
        }

        if (query) {
          return (
            question.promptText.toLowerCase().includes(query) ||
            question.responseText.toLowerCase().includes(query)
          );
        }

        return true;
      });
  }
}
