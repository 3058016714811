<div class="mt-3 flex flex-1 flex-row justify-center">
  <form [formGroup]="form" class="flex w-full max-w-[500px] flex-col">
    <div dominionPopoverHost class="flex flex-row">
      <textarea
        dominionAutofocus
        [enableAutofocus]="isCurrentQuestion"
        dominionPopoverTarget
        class="ring-dms-light-green flex min-h-[250px] flex-1 rounded border border-gray-300 p-2 outline-none ring-offset-1 focus:ring"
        formControlName="text"
        name="text"
        (blur)="checkErrors()"
        (input)="checkErrors()"
      ></textarea>
      <div dominionPopover>
        <span dominionStandardFormError>Please enter a response.</span>
      </div>
    </div>
    <div class="mt-8 flex items-center">
      @if (data && data.log.length > 0) {
        <dominion-response-history-trigger
          [log]="data.log"
        ></dominion-response-history-trigger>
      }

      <div class="flex-1"></div>

      <button
        dominionDiscoveryNextBtn
        [disabled]="form.invalid"
        [ngClass]="{ disabled: form.invalid }"
        type="button"
        (click)="save()"
      >
        Next
      </button>
    </div>
  </form>
</div>
