import { HydratedDocument, Model, Types } from 'mongoose';
import { IInvitedUser } from './invited-user.interfaces';
import { IUser, IUserBase } from './user.interfaces';

export interface IUserArchive
  extends Omit<
    IUserBase,
    'companies' | 'internal' | 'external' | 'authorizations'
  > {
  firstName?: string;
  lastName?: string;
  registeredOn?: IUser['registeredOn'];
  loginDates?: IUser['loginDates'];
  authentication?: IUser['authentication'];
  jobTitle?: IUser['jobTitle'];
  department?: IUser['department'];
  invite?: IInvitedUser['invite'];
  archiver: {
    _id: string;
    archivedOn: Date;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export interface IUserArchiveModelOverrides {
  _id: Types.ObjectId;
}

export interface IUserArchiveModel
  extends Model<IUserArchive, object, IUserArchiveModelOverrides> {}

export interface IUserArchiveDocument
  extends HydratedDocument<IUserArchive, IUserArchiveModelOverrides> {}
