import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TServiceDefaultGroupIdentifiers =
  | 'groupServiceDefaultGeneral'
  | 'groupServiceDefaultFinancial'
  | 'groupServiceDefaultPrinting'
  | 'groupServiceDefaultOilLabel'
  | 'groupServiceDefaultDeclined'
  | 'groupServiceDefaultOpCodes'
  | 'groupServiceDefaultAdvisors'
  | 'groupCaliforniaAndFlorida'
  | 'groupBodyShopRO'
  | 'groupCustomerPayRO'
  | 'groupExtendedServiceRO'
  | 'groupInternalPayRO'
  | 'groupWarrantyPayRO'
  | 'groupQuickLubeRO';

export type TServiceDefaultDataKeys =
  | 'defaultApptAdvisor'
  | 'requireApptAdvisor'
  | 'promiseTime'
  | 'useDefaultROConcern'
  | 'defaultROConcern'
  | 'requireOpCodeCustomerPay'
  | 'requireOpCodeServicePay'
  | 'requireOpCodeWarrantyPay'
  | 'requireOpCodeInternalPay'
  | 'appointmentAdvisorRO'
  | 'noApptAdvisorRO'
  | 'advisorRO'
  | 'updateROMileage'
  | 'defaultNotificationMethod'
  | 'allowMultiDeptRO'
  | 'promptPrintPO'
  | 'promptUpdateSublet'
  | 'autoVIS'
  | 'attachWOOnRelease'
  | 'releaseROAction'
  | 'requireROTech'
  | 'flagHourValidation'
  | 'partsRecoverOps'
  | 'editFixedPriceParts'
  | 'paintMarkup'
  | 'partsRequestRoute'
  | 'extendedServiceControl'
  | 'serviceAccountCode'
  | 'bodyShopAccountCode'
  | 'quickLubeAccountCode'
  | 'customerPayAccountCode'
  | 'internalPayAccountCode'
  | 'extendedServicePayAccountCode'
  | 'warrantyPayAccountCode'
  | 'promptPrintROClose'
  | 'printAuthorizedAdditions'
  | 'printROLabor'
  | 'printROInvoiceTechInfo'
  | 'printWOTechInfo'
  | 'techNameFormat'
  | 'internalPrintPartCost'
  | 'printROBarcode'
  | 'printROSubletInfo'
  | 'printOilLabel1'
  | 'printOilLabel2'
  | 'printOilLabel3'
  | 'printOilLabel4'
  | 'printOilLabelMonths'
  | 'printOilLabelMiles'
  | 'requireHatNumber'
  | 'requireROEmail'
  | 'printGMLifetimeWarranty'
  | 'printWarrantySaleAmount'
  | 'printExtendedServiceSaleAmount'
  | 'printGLInfoRO'
  | 'stateDisclosurePreWO'
  | 'stateDisclosureWO'
  | 'stateDisclosureRO'
  | 'stateDisclosureInternalRO'
  | 'warrantyRecapTechCost'
  | 'declinedDeferred'
  | 'showDeclineRO'
  | 'showDeclineMPI'
  | 'showDeclineMaintMenu'
  | 'locatedInCAorFL'
  | 'isGMDealer'
  | 'payByActualHours'
  | 'bodyShopRO'
  | 'hasQuickLube'
  | 'customerPayRO'
  | 'extendedServiceRO'
  | 'internalPayRO'
  | 'hasBodyShop'
  | 'quickLubeRO'
  | 'warrantyPayRO';

export const serviceDefaultGroupMap = {
  moduleType: 'service',
  submoduleType: 'default',
  discriminator: 'service-default',
  groups: {
    groupServiceDefaultGeneral: [
      'promiseTime',
      'useDefaultROConcern',
      'defaultROConcern',
      'updateROMileage',
      'defaultNotificationMethod',
      'allowMultiDeptRO',
      'promptPrintPO',
      'promptUpdateSublet',
      'autoVIS',
      'attachWOOnRelease',
      'releaseROAction',
      'requireROTech',
      'flagHourValidation',
      'partsRecoverOps',
      'editFixedPriceParts',
      'paintMarkup',
      'partsRequestRoute',
      'payByActualHours',
    ],
    groupServiceDefaultAdvisors: [
      'defaultApptAdvisor',
      'requireApptAdvisor',
      'appointmentAdvisorRO',
      'noApptAdvisorRO',
      'advisorRO',
    ],
    groupServiceDefaultOpCodes: [
      'requireOpCodeCustomerPay',
      'requireOpCodeServicePay',
      'requireOpCodeWarrantyPay',
      'requireOpCodeInternalPay',
    ],
    groupServiceDefaultFinancial: [
      'extendedServiceControl',
      'serviceAccountCode',
      'bodyShopAccountCode',
      'quickLubeAccountCode',
      'customerPayAccountCode',
      'internalPayAccountCode',
      'extendedServicePayAccountCode',
      'warrantyPayAccountCode',
    ],
    groupServiceDefaultPrinting: [
      'promptPrintROClose',
      'printROLabor',
      'printROInvoiceTechInfo',
      'printWOTechInfo',
      'techNameFormat',
      'internalPrintPartCost',
      'printROBarcode',
      'printROSubletInfo',
      'requireHatNumber',
      'requireROEmail',
      'isGMDealer',
      'printGMLifetimeWarranty',
      'printWarrantySaleAmount',
      'printExtendedServiceSaleAmount',
      'printGLInfoRO',
      'warrantyRecapTechCost',
    ],
    groupServiceDefaultOilLabel: [
      'printOilLabel1',
      'printOilLabel2',
      'printOilLabel3',
      'printOilLabel4',
      'printOilLabelMonths',
      'printOilLabelMiles',
    ],
    groupServiceDefaultDeclined: [
      'declinedDeferred',
      'showDeclineRO',
      'showDeclineMPI',
      'showDeclineMaintMenu',
    ],
    groupQuickLubeRO: ['quickLubeRO', 'hasQuickLube'],
    groupBodyShopRO: ['hasBodyShop', 'bodyShopRO'],
    groupCustomerPayRO: ['customerPayRO'],
    groupExtendedServiceRO: ['extendedServiceRO'],
    groupInternalPayRO: ['internalPayRO'],
    groupWarrantyPayRO: ['warrantyPayRO'],

    groupCaliforniaAndFlorida: [
      'locatedInCAorFL',
      'stateDisclosurePreWO',
      'stateDisclosureWO',
      'stateDisclosureRO',
      'stateDisclosureInternalRO',
      'printAuthorizedAdditions',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TServiceDefaultGroupIdentifiers,
  TServiceDefaultDataKeys
>;

export type TServiceDefaultGroupMap = typeof serviceDefaultGroupMap;

export class ServiceDefaultData
  implements TDataDefinition<TServiceDefaultDataKeys>
{
  defaultApptAdvisor = new DataInitializerBasic<'vin' | 'creator' | 'blank'>();
  requireApptAdvisor = new DataInitializerBasic<'require' | 'allow-blank'>();
  promiseTime = new DataInitializerBasic<string>();
  useDefaultROConcern = new DataInitializerBasic<boolean>();
  defaultROConcern = new DataInitializerBasic<string>();
  requireOpCodeCustomerPay = new DataInitializerBasic<boolean>();
  requireOpCodeServicePay = new DataInitializerBasic<boolean>();
  requireOpCodeWarrantyPay = new DataInitializerBasic<boolean>();
  requireOpCodeInternalPay = new DataInitializerBasic<boolean>();
  appointmentAdvisorRO = new DataInitializerBasic<boolean>();
  noApptAdvisorRO = new DataInitializerBasic<'vin' | 'creator'>();
  advisorRO = new DataInitializerBasic<'vin' | 'creator'>();
  updateROMileage = new DataInitializerBasic<boolean>();
  defaultNotificationMethod = new DataInitializerBasic<
    'call' | 'email' | 'text' | 'none'
  >();
  allowMultiDeptRO = new DataInitializerBasic<boolean>();
  promptPrintPO = new DataInitializerBasic<boolean>();
  promptUpdateSublet = new DataInitializerBasic<boolean>();
  autoVIS = new DataInitializerBasic<boolean>();
  attachWOOnRelease = new DataInitializerBasic<boolean>();
  releaseROAction = new DataInitializerBasic<'print' | 'signature' | 'both'>();
  requireROTech = new DataInitializerBasic<boolean>();
  flagHourValidation = new DataInitializerBasic<boolean>();
  partsRecoverOps = new DataInitializerBasic<boolean>();
  editFixedPriceParts = new DataInitializerBasic<boolean>();
  paintMarkup = new DataInitializerBasic<string>();
  partsRequestRoute = new DataInitializerBasic<'order-fill' | 'p-and-a'>();
  extendedServiceControl = new DataInitializerBasic<'customer' | 'vendor'>();
  serviceAccountCode = new DataInitializerBasic<string>();
  bodyShopAccountCode = new DataInitializerBasic<string>();
  quickLubeAccountCode = new DataInitializerBasic<string>();
  customerPayAccountCode = new DataInitializerBasic<string>();
  internalPayAccountCode = new DataInitializerBasic<string>();
  extendedServicePayAccountCode = new DataInitializerBasic<string>();
  warrantyPayAccountCode = new DataInitializerBasic<string>();
  promptPrintROClose = new DataInitializerBasic<
    'not-printed' | 'modified' | 'always' | 'never'
  >();
  printAuthorizedAdditions = new DataInitializerBasic<boolean>();
  printROLabor = new DataInitializerBasic<'total' | 'by-tech' | 'never'>();
  printROInvoiceTechInfo = new DataInitializerBasic<
    | 'employee-number'
    | 'name-and-number'
    | 'name'
    | 'name-and-cert'
    | 'cert'
    | 'none'
  >();
  printWOTechInfo = new DataInitializerBasic<
    'employee-number' | 'name-and-number' | 'name' | 'none'
  >();
  techNameFormat = new DataInitializerBasic<
    'first-last-initial' | 'last-first' | 'last-6-first'
  >();
  internalPrintPartCost = new DataInitializerBasic<boolean>();
  printROBarcode = new DataInitializerBasic<boolean>();
  printROSubletInfo = new DataInitializerBasic<boolean>();
  printOilLabel1 = new DataInitializerBasic<string>();
  printOilLabel2 = new DataInitializerBasic<string>();
  printOilLabel3 = new DataInitializerBasic<string>();
  printOilLabel4 = new DataInitializerBasic<string>();
  printOilLabelMonths = new DataInitializerBasic<string>();
  printOilLabelMiles = new DataInitializerBasic<string>();
  requireHatNumber = new DataInitializerBasic<boolean>();
  requireROEmail = new DataInitializerBasic<boolean>();
  printGMLifetimeWarranty = new DataInitializerBasic<boolean>();
  printWarrantySaleAmount = new DataInitializerBasic<boolean>();
  printExtendedServiceSaleAmount = new DataInitializerBasic<boolean>();
  printGLInfoRO = new DataInitializerBasic<boolean>();
  stateDisclosurePreWO = new DataInitializerBasic<boolean>();
  stateDisclosureWO = new DataInitializerBasic<boolean>();
  stateDisclosureRO = new DataInitializerBasic<boolean>();
  stateDisclosureInternalRO = new DataInitializerBasic<boolean>();
  warrantyRecapTechCost = new DataInitializerBasic<boolean>();
  declinedDeferred = new DataInitializerBasic<'declined' | 'deferred'>();
  showDeclineRO = new DataInitializerBasic<boolean>();
  showDeclineMPI = new DataInitializerBasic<boolean>();
  showDeclineMaintMenu = new DataInitializerBasic<boolean>();
  locatedInCAorFL = new DataInitializerBasic<boolean>();
  isGMDealer = new DataInitializerBasic<boolean>();
  payByActualHours = new DataInitializerBasic<boolean>();
  warrantyPayRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  quickLubeRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  hasBodyShop = new DataInitializerBasic<boolean>();
  bodyShopRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  hasQuickLube = new DataInitializerBasic<boolean>();
  customerPayRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  extendedServiceRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  internalPayRO = new DataInitializerBasic<IFileUploadReference[]>([]);
}

export const serviceDefaultStructure = {
  moduleType: 'service',
  submoduleType: 'default',
  discriminator: 'service-default',
  groups: {
    groupServiceDefaultGeneral: {
      groupLabel: 'General',
      groupUrl: 'groupServiceDefaultGeneral',
      groupType: 'standard',
      questions: {
        promiseTime: {
          questionKey: 'promiseTime',
          label: 'Promise Time',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a repair order (RO), VUE should set the promise time to:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis. For example, VUE can automatically assign a promise time of 5:00 PM.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        useDefaultROConcern: {
          questionKey: 'useDefaultROConcern',
          label: 'Use Default RO Concern',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When manually adding an operation to a repair order (RO), should VUE pre-populate the "Concern" field with standard wording?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For instance, some dealers choose to have VUE pre-populate the "Concern" field to say: "Customer states:". This default value can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['defaultROConcern'],
            },
          ],
        },
        defaultROConcern: {
          questionKey: 'defaultROConcern',
          label: 'Default RO Concern',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should pre-populate the "Concern" field on the RO to say:',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        updateROMileage: {
          questionKey: 'updateROMileage',
          label: 'Update RO Mileage',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating and closing repair orders (ROs), VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'If you select "Yes," VUE will require that the "Mileage In" value is at least 1 greater than the VIN mileage when creating an RO. VUE will also require that the "Mileage Out" value is at least 1 greater than the "Mileage In" value when closing an RO.',
            },
          ],
          options: [
            {
              value: true,
              label:
                'Require technicians to update the "Mileage In" and "Mileage Out" fields with valid values.',
            },
            {
              value: false,
              label:
                'Do not require or validate the "Mileage In" or "Mileage Out" fields.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        defaultNotificationMethod: {
          questionKey: 'defaultNotificationMethod',
          label: 'Default Notification Method',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a repair order (RO), VUE should default to notifying the customer by:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: 'call',
              label: 'Call',
            },
            {
              value: 'email',
              label: 'Email',
            },
            {
              value: 'text',
              label: 'Text',
            },
            {
              value: 'none',
              label: 'None (leave blank)',
            },
          ],
          validation: [],
          dependencies: [],
        },
        allowMultiDeptRO: {
          questionKey: 'allowMultiDeptRO',
          label: 'Allow Multi-Department RO',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a repair order (RO), VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label:
                'Allow the user to select any account codes, regardless of the department type.',
            },
            {
              value: false,
              label:
                'Restrict the user to selecting only account codes that match the department type.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        promptPrintPO: {
          questionKey: 'promptPrintPO',
          label: 'Prompt Print PO',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'After creating a purchase order (PO), should VUE prompt the user to print the PO?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This setting simply determines whether VUE reminds the user to print the PO.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        promptUpdateSublet: {
          questionKey: 'promptUpdateSublet',
          label: 'Prompt Update Sublet',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When marking a repair order (RO) operation as complete, should VUE remind the user to also update the status of any operation sublets?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        autoVIS: {
          questionKey: 'autoVIS',
          label: 'Auto VIS',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a VIN is entered on an appointment or repair order (RO), should VUE automatically run an OEM inquiry to identify any outstanding recalls or other vehicle issues?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose to run the VIN inquiry automatically. Users can always run the inquiry manually.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        attachWOOnRelease: {
          questionKey: 'attachWOOnRelease',
          label: 'Attach WO on Release',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When releasing a repair order (RO), should VUE attach the work order to the RO?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Attaching the work order to the RO means that the work order and the RO will be linked digitally within VUE. If you choose not to automatically attach the work order to the RO on release, then the user will have to manually attach the two if desired.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        releaseROAction: {
          questionKey: 'releaseROAction',
          label: 'Release RO Action',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When releasing a repair order (RO), VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'print',
              label: 'Print the work order.',
            },
            {
              value: 'signature',
              label:
                'Open the authorization screen to collect an electronic signature.',
            },
            {
              value: 'both',
              label:
                'Open the authorization screen, collect the e-signature, and then print the work order.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireROTech: {
          questionKey: 'requireROTech',
          label: 'Require RO Tech',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When marking an operation as complete on a repair order (RO), VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Require that a technician is assigned to the operation.',
            },
            {
              value: false,
              label:
                'Allow the operation to be marked complete even if no technician is assigned.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        flagHourValidation: {
          questionKey: 'flagHourValidation',
          label: 'Flag Hour Validation',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Should VUE require the service manager to approve warranty flag hours before an operation can be completed and posted?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        partsRecoverOps: {
          questionKey: 'partsRecoverOps',
          label: 'Parts Recover Ops',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'After a repair order (RO) operation has been closed, should VUE allow authorized ',
            },
            {
              attributes: { bold: true },
              text: 'parts personnel ',
            },
            {
              attributes: null,
              text: 'to reopen the closed operation?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        editFixedPriceParts: {
          questionKey: 'editFixedPriceParts',
          label: 'Edit Fixed Price Parts',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Should VUE allow authorized service personnel to edit the price of parts on a ',
            },
            {
              attributes: { bold: true },
              text: 'fixed price operation',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose "No."\n\nIf you choose "Yes," only authorized service personnel will be allowed to edit prices.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        paintMarkup: {
          questionKey: 'paintMarkup',
          label: 'Paint Markup',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a body shop repair order (RO), VUE should automatically mark up the ',
            },
            {
              attributes: { bold: true },
              text: 'paint supply cost ',
            },
            {
              attributes: null,
              text: ' by what percentage to determine the price?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        partsRequestRoute: {
          questionKey: 'partsRequestRoute',
          label: 'Parts Request Route',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When completing a parts request, VUE should automatically send the request to the:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose to send the request to the P&A department. This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: 'order-fill',
              label: 'Order Fill Department',
            },
            {
              value: 'p-and-a',
              label: 'Price and Availability (P&A) Department',
            },
          ],
          validation: [],
          dependencies: [],
        },
        payByActualHours: {
          questionKey: 'payByActualHours',
          label: 'Pay By Actual Hours',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When calculating technician pay, should VUE use the actual hours worked?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDefaultAdvisors: {
      groupLabel: 'Advisors',
      groupUrl: 'groupServiceDefaultAdvisors',
      groupType: 'standard',
      questions: {
        defaultApptAdvisor: {
          questionKey: 'defaultApptAdvisor',
          label: 'Default Appointment Advisor',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a service appointent, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: 'vin',
              label:
                'Set the appointment advisor to the advisor on the last RO associated with the VIN.',
            },
            {
              value: 'creator',
              label:
                'Set the appointment advisor to the advisor who is creating the appointment.',
            },
            {
              value: 'blank',
              label: 'Leave the advisor field blank.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireApptAdvisor: {
          questionKey: 'requireApptAdvisor',
          label: 'Require Appointment Advisor',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a service appointment, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'require',
              label: 'Require the service appointment to have an advisor.',
            },
            {
              value: 'allow-blank',
              label: 'Allow the user to leave the advisor field blank.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        appointmentAdvisorRO: {
          questionKey: 'appointmentAdvisorRO',
          label: 'Appointment Advisor on RO',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a repair order (RO) ',
            },
            {
              attributes: { bold: true },
              text: 'from an appointment ',
            },
            {
              attributes: null,
              text: ', should VUE automatically assign the ',
            },
            {
              attributes: { bold: true },
              text: 'appointment advisor ',
            },
            {
              attributes: null,
              text: 'to the RO?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['noApptAdvisorRO'],
            },
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['advisorRO'],
            },
          ],
        },
        noApptAdvisorRO: {
          questionKey: 'noApptAdvisorRO',
          label: 'No Appointment Advisor',
          componentType: 'select-list',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating an RO ',
            },
            {
              attributes: { bold: true },
              text: 'without an appointment',
            },
            {
              attributes: null,
              text: ', VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: 'vin',
              label:
                'Assign the advisor associated with the VIN as the RO advisor.',
            },
            {
              value: 'creator',
              label: 'Assign the advisor creating the RO as the RO advisor.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        advisorRO: {
          questionKey: 'advisorRO',
          label: 'Advisor on RO',
          componentType: 'select-list',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating an RO, VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [
            {
              value: 'vin',
              label:
                'Assign the advisor associated with the VIN as the RO advisor.',
            },
            {
              value: 'creator',
              label: 'Assign the advisor creating the RO as the RO advisor.',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDefaultOpCodes: {
      groupLabel: 'Op Codes',
      groupUrl: 'groupServiceDefaultOpCodes',
      groupType: 'standard',
      questions: {
        requireOpCodeCustomerPay: {
          questionKey: 'requireOpCodeCustomerPay',
          label: 'Require Customer Pay Op Codes',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Should VUE require an op code to be attached when the pay type is ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireOpCodeServicePay: {
          questionKey: 'requireOpCodeServicePay',
          label: 'Require Service Pay Op Codes',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Should VUE require an op code to be attached when the pay type is ',
            },
            {
              attributes: { bold: true },
              text: 'extended service pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireOpCodeWarrantyPay: {
          questionKey: 'requireOpCodeWarrantyPay',
          label: 'Require Warranty Pay Op Codes',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Should VUE require an op code to be attached when the pay type is ',
            },
            {
              attributes: { bold: true },
              text: 'warranty pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireOpCodeInternalPay: {
          questionKey: 'requireOpCodeInternalPay',
          label: 'Require Internal Pay Op Codes',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Should VUE require an op code to be attached when the pay type is ',
            },
            {
              attributes: { bold: true },
              text: 'internal pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDefaultDeclined: {
      groupLabel: 'Declined/Deferred Work',
      groupUrl: 'groupServiceDefaultDeclined',
      groupType: 'standard',
      questions: {
        declinedDeferred: {
          questionKey: 'declinedDeferred',
          label: 'Declined or Deferred',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a customer chooses not to proceed with a repair operation, VUE should show that the operation was:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'declined',
              label: 'Declined by the customer.',
            },
            {
              value: 'deferred',
              label: 'Deferred by the customer.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        showDeclineRO: {
          questionKey: 'showDeclineRO',
          label: 'Show Decline on RO',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a customer chooses not to proceed with a repair operation, should VUE show that the operation was declined/deferred on the RO?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        showDeclineMPI: {
          questionKey: 'showDeclineMPI',
          label: 'Show Decline on MPI',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a customer chooses not to proceed with a repair operation, should VUE show that the operation was declined/deferred on the multi-point inspection (MPI)?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        showDeclineMaintMenu: {
          questionKey: 'showDeclineMaintMenu',
          label: 'Show Decline on Maintenance Menu',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a customer chooses not to proceed with a repair operation, should VUE show that the operation was declined/deferred on the maintenance menu?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDefaultFinancial: {
      groupLabel: 'Financial',
      groupUrl: 'groupServiceDefaultFinancial',
      groupType: 'standard',
      questions: {
        extendedServiceControl: {
          questionKey: 'extendedServiceControl',
          label: 'Extended Service Control',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a receivable account is paid by an ',
            },
            {
              attributes: { bold: true },
              text: 'extended service vendor ',
            },
            {
              attributes: null,
              text: ', VUE should control the receivable account using:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This setting determines whether the customer number or the extended service vendor number should be the primary method for identifying and tracking payments from the vendor.',
            },
          ],
          options: [
            {
              value: 'customer',
              label: 'The customer number.',
            },
            {
              value: 'vendor',
              label: 'The extended service vendor number.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        serviceAccountCode: {
          questionKey: 'serviceAccountCode',
          label: 'Service Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for ',
            },
            {
              attributes: { bold: true },
              text: 'service',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        bodyShopAccountCode: {
          questionKey: 'bodyShopAccountCode',
          label: 'Body Shop Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for the ',
            },
            {
              attributes: { bold: true },
              text: 'body shop',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        quickLubeAccountCode: {
          questionKey: 'quickLubeAccountCode',
          label: 'Quick Lube Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for the ',
            },
            {
              attributes: { bold: true },
              text: 'quick lube',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        customerPayAccountCode: {
          questionKey: 'customerPayAccountCode',
          label: 'Customer Pay Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        internalPayAccountCode: {
          questionKey: 'internalPayAccountCode',
          label: 'Internal Pay Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for ',
            },
            {
              attributes: { bold: true },
              text: 'internal pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        extendedServicePayAccountCode: {
          questionKey: 'extendedServicePayAccountCode',
          label: 'Extended Service Pay Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for ',
            },
            {
              attributes: { bold: true },
              text: 'extended service pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        warrantyPayAccountCode: {
          questionKey: 'warrantyPayAccountCode',
          label: 'Warranty Pay Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which account code as the default account code for ',
            },
            {
              attributes: { bold: true },
              text: 'warranty pay',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is simply a default and can be edited on a case-by-case basis.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDefaultOilLabel: {
      groupLabel: 'Oil Label',
      groupUrl: 'groupServiceDefaultOilLabel',
      groupType: 'standard',
      questions: {
        printOilLabel1: {
          questionKey: 'printOilLabel1',
          label: 'Oil Label 1',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When printing an oil change label, VUE should list the dealership's name as:",
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        printOilLabel2: {
          questionKey: 'printOilLabel2',
          label: 'Oil Label 2',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When printing an oil change label, VUE should list the dealership's address as:",
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        printOilLabel3: {
          questionKey: 'printOilLabel3',
          label: 'Oil Label 3',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When printing an oil change label, VUE should list the dealership's phone number as:",
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        printOilLabel4: {
          questionKey: 'printOilLabel4',
          label: 'Oil Label 4',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing an oil change label, VUE should include the following message:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For example, some dealerships choose to say, "Thank you!"',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        printOilLabelMonths: {
          questionKey: 'printOilLabelMonths',
          label: 'Oil Label Months',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing an oil change label, VUE should specify that the next oil change is due in how many ',
            },
            {
              attributes: { bold: true },
              text: 'months',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        printOilLabelMiles: {
          questionKey: 'printOilLabelMiles',
          label: 'Oil Label Miles',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing an oil change label, VUE should specify that the next oil change is due in how many ',
            },
            {
              attributes: { bold: true },
              text: 'miles',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDefaultPrinting: {
      groupLabel: 'Printing',
      groupUrl: 'groupServiceDefaultPrinting',
      groupType: 'standard',
      questions: {
        promptPrintROClose: {
          questionKey: 'promptPrintROClose',
          label: 'Prompt Print RO Close',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When closing a repair order (RO), VUE should:',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This setting simply determines whether VUE reminds the user to print the RO.',
            },
          ],
          options: [
            {
              value: 'not-printed',
              label:
                'Prompt the user to print if the RO was not previously printed.',
            },
            {
              value: 'modified',
              label:
                'Prompt the user to print if the RO was modified since the last print.',
            },
            {
              value: 'always',
              label: 'Always prompt the user to print.',
            },
            {
              value: 'never',
              label: 'Never prompt the user to print.',
            },
          ],
          validation: [],
          dependencies: [],
        },

        printROLabor: {
          questionKey: 'printROLabor',
          label: 'Print RO Labor',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a repair order (RO) invoice, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'total',
              label: 'List the total labor charge.',
            },
            {
              value: 'by-tech',
              label: 'List the labor charge for each technician individually.',
            },
            {
              value: 'never',
              label: 'Never list the labor charge.',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: 'by-tech',
              dependentKeys: ['printROInvoiceTechInfo'],
            },
          ],
        },
        printROInvoiceTechInfo: {
          questionKey: 'printROInvoiceTechInfo',
          label: 'Print RO Invoice Tech Info',
          componentType: 'select-list',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When printing a repair order (RO) invoice, VUE should include the technician's:",
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'employee-number',
              label: 'Employee number',
            },
            {
              value: 'name-and-number',
              label: 'Employee number and name',
            },
            {
              value: 'name',
              label: 'Name',
            },
            {
              value: 'name-and-cert',
              label: 'Name and certification number',
            },
            {
              value: 'cert',
              label: 'Certification number',
            },
            {
              value: 'none',
              label: 'Do not include technician information',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printWOTechInfo: {
          questionKey: 'printWOTechInfo',
          label: 'Print WO Tech Info',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a work order (WO), VUE should include the technician information as:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'employee-number',
              label: 'Employee number',
            },
            {
              value: 'name-and-number',
              label: 'Employee number and name',
            },
            {
              value: 'name',
              label: 'Name',
            },
            {
              value: 'none',
              label: 'Do not include technician information',
            },
          ],
          validation: [],
          dependencies: [],
        },
        techNameFormat: {
          questionKey: 'techNameFormat',
          label: 'Tech Name Format',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When VUE includes a technician's information on a repair order, it should format the technician's name as:",
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'first-last-initial',
              label: 'First name, last name initial',
            },
            {
              value: 'last-first',
              label: 'Last name, first name',
            },
            {
              value: 'last-6-first',
              label: 'First 6 letters of last name, first name',
            },
          ],
          validation: [],
          dependencies: [],
        },
        internalPrintPartCost: {
          questionKey: 'internalPrintPartCost',
          label: 'Internal Print Part Cost',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing an ',
            },
            {
              attributes: { bold: true },
              text: 'internal copy ',
            },
            {
              attributes: null,
              text: 'of a repair order (RO), should VUE show the cost of parts?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This applies only to internal copies of ROs. This does not affect what customers see on their copy of the RO.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printROBarcode: {
          questionKey: 'printROBarcode',
          label: 'Print RO Barcode',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a repair order (RO), should VUE include a barcode?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printROSubletInfo: {
          questionKey: 'printROSubletInfo',
          label: 'Print RO Sublet Info',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a repair order (RO), should VUE show sublet information for operations?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireHatNumber: {
          questionKey: 'requireHatNumber',
          label: 'Require HAT Number',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a work order, should VUE require a vehicle hat number?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        requireROEmail: {
          questionKey: 'requireROEmail',
          label: 'Require RO Email',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When creating a work order, should VUE require the customer's email address?",
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printWarrantySaleAmount: {
          questionKey: 'printWarrantySaleAmount',
          label: 'Print Warranty Sale Amount',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When generating an invoice for a ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay ',
            },
            {
              attributes: null,
              text: 'transaction, should VUE show the ',
            },
            {
              attributes: { bold: true },
              text: 'warranty sale amount',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'If you choose "No," only charges and credits that are not related to warranty work will be shown on the invoice.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printExtendedServiceSaleAmount: {
          questionKey: 'printExtendedServiceSaleAmount',
          label: 'Print Extended Service Sale Amount',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When generating an invoice for a ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay ',
            },
            {
              attributes: null,
              text: 'transaction, should VUE show the ',
            },
            {
              attributes: { bold: true },
              text: 'extended service sale amount',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'If you choose "No," only charges and credits that are not related to extended service work will be shown on the invoice.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printGLInfoRO: {
          questionKey: 'printGLInfoRO',
          label: 'Print GL Info on RO',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing an ',
            },
            {
              attributes: { bold: true },
              text: 'internal copy ',
            },
            {
              attributes: null,
              text: 'of a repair order (RO) invoice, should VUE show where on the general ledger each item will be posted?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        warrantyRecapTechCost: {
          questionKey: 'warrantyRecapTechCost',
          label: 'Warranty Recap Tech Cost',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a warranty recap, should VUE show the technician cost?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This is for internal use only. It is not customer-facing.',
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        isGMDealer: {
          questionKey: 'isGMDealer',
          label: 'Is GM Dealer',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Are you a GM dealer?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['printGMLifetimeWarranty'],
            },
          ],
        },
        printGMLifetimeWarranty: {
          questionKey: 'printGMLifetimeWarranty',
          label: 'Print GM Lifetime Warranty',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When printing a repair order (RO) invoice, should VUE show that a part is eligible for GM's lifetime warranty?",
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupBodyShopRO: {
      groupLabel: 'Body Shop RO',
      groupUrl: 'groupBodyShopRO',
      groupType: 'standard',
      questions: {
        hasBodyShop: {
          questionKey: 'hasBodyShop',
          label: 'Do you have a body shop?',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have a body shop?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['bodyShopRO'],
            },
          ],
        },
        bodyShopRO: {
          questionKey: 'bodyShopRO',
          label: 'Body Shop RO',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'body shop pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'To monitor the correct flow of money from service into accounting, and to ensure we can accurately recreate repair orders in VUE before go-live, we need the following pertaining to ',
            },
            {
              attributes: { bold: true },
              text: 'body shop pay',
            },
            {
              attributes: null,
              text: ':\n\n    \u2022 the pricing used (Price Code, Labor Rate) (Calc C+30, Matrix, Retail, List, etc.)\n    \u2022 posting information (Account Code, Journal, etc.)\n\nIf you need to attach multiple documents, please label them accordingly.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupQuickLubeRO: {
      groupLabel: 'Quick Lube RO',
      groupUrl: 'groupQuickLubeRO',
      groupType: 'standard',
      questions: {
        hasQuickLube: {
          questionKey: 'hasQuickLube',
          label: 'Do you have a quick lube?',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have a quick lube?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['quickLubeRO'],
            },
          ],
        },
        quickLubeRO: {
          questionKey: 'quickLubeRO',
          label: 'Quick Lube RO',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'quick lube pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'To monitor the correct flow of money from service into accounting, and to ensure we can accurately recreate repair orders in VUE before go-live, we need the following pertaining to ',
            },
            {
              attributes: { bold: true },
              text: 'quick lube pay',
            },
            {
              attributes: null,
              text: ':\n\n    \u2022 the pricing used (Price Code, Labor Rate) (Calc C+30,\n       Matrix, Retail, List, etc.)\n    \u2022 posting information (Account Code, Journal, etc.)\n\nIf you need to attach multiple documents, please label them accordingly.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupCustomerPayRO: {
      groupLabel: 'Customer Pay RO',
      groupUrl: 'groupCustomerPayRO',
      groupType: 'standard',
      questions: {
        customerPayRO: {
          questionKey: 'customerPayRO',
          label: 'Customer Pay RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'To monitor the correct flow of money from service into accounting, and to ensure we can accurately recreate repair orders in VUE before go-live, we need the following pertaining to ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay',
            },
            {
              attributes: null,
              text: ':\n\n    \u2022 the pricing used (Price Code, Labor Rate) (Calc C+30,\n       Matrix, Retail, List, etc.)\n    \u2022 posting information (Account Code, Journal, etc.)\n\nIf you need to attach multiple documents, please label them accordingly.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupExtendedServiceRO: {
      groupLabel: 'Extended Service RO',
      groupUrl: 'groupExtendedServiceRO',
      groupType: 'standard',
      questions: {
        extendedServiceRO: {
          questionKey: 'extendedServiceRO',
          label: 'Extended Service RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'extended service pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'To monitor the correct flow of money from service into accounting, and to ensure we can accurately recreate repair orders in VUE before go-live, we need the following pertaining to ',
            },
            {
              attributes: { bold: true },
              text: 'extended service pay',
            },
            {
              attributes: null,
              text: ':\n\n    \u2022 the pricing used (Price Code, Labor Rate) (Calc C+30,\n       Matrix, Retail, List, etc.)\n    \u2022 posting information (Account Code, Journal, etc.)\n\nIf you need to attach multiple documents, please label them accordingly.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupInternalPayRO: {
      groupLabel: 'Internal Pay RO',
      groupUrl: 'groupInternalPayRO',
      groupType: 'standard',
      questions: {
        internalPayRO: {
          questionKey: 'internalPayRO',
          label: 'Internal Pay RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'internal pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'To monitor the correct flow of money from service into accounting, and to ensure we can accurately recreate repair orders in VUE before go-live, we need the following pertaining to ',
            },
            {
              attributes: { bold: true },
              text: 'internal pay',
            },
            {
              attributes: null,
              text: ':\n\n    \u2022 the pricing used (Price Code, Labor Rate) (Calc C+30,\n       Matrix, Retail, List, etc.)\n    \u2022 posting information (Account Code, Journal, etc.)\n\nIf you need to attach multiple documents, please label them accordingly.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupWarrantyPayRO: {
      groupLabel: 'Warranty Pay RO',
      groupUrl: 'groupWarrantyPayRO',
      groupType: 'standard',
      questions: {
        warrantyPayRO: {
          questionKey: 'warrantyPayRO',
          label: 'Warranty Pay RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'warranty pay. ',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'To monitor the correct flow of money from service into accounting, and to ensure we can accurately recreate repair orders in VUE before go-live, we need the following pertaining to ',
            },
            {
              attributes: { bold: true },
              text: 'warranty pay',
            },
            {
              attributes: null,
              text: ':\n\n    \u2022 the pricing used (Price Code, Labor Rate) (Calc C+30,\n       Matrix, Retail, List, etc.)\n    \u2022 posting information (Account Code, Journal, etc.)\n\nIf you need to attach multiple documents, please label them accordingly.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupCaliforniaAndFlorida: {
      groupLabel: 'California and Florida',
      groupUrl: 'groupCaliforniaAndFlorida',
      groupType: 'standard',
      questions: {
        locatedInCAorFL: {
          questionKey: 'locatedInCAorFL',
          label: 'Located in CA or FL',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Is your dealership located in California or Florida?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'stateDisclosurePreWO',
                'stateDisclosureWO',
                'stateDisclosureRO',
                'stateDisclosureInternalRO',
                'printAuthorizedAdditions',
              ],
            },
          ],
        },
        printAuthorizedAdditions: {
          questionKey: 'printAuthorizedAdditions',
          label: 'Print Authorized Additions',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a repair order (RO) that contains an ',
            },
            {
              attributes: { bold: true },
              text: 'authorization for additional work',
            },
            {
              attributes: null,
              text: ', should VUE include the authorization form on the printed RO?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },

        stateDisclosurePreWO: {
          questionKey: 'stateDisclosurePreWO',
          label: 'State Disclosure Pre-Work Order',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Should VUE include your state's required disclosures on ",
            },
            {
              attributes: { bold: true },
              text: 'pre-work orders',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        stateDisclosureWO: {
          questionKey: 'stateDisclosureWO',
          label: 'State Disclosure Work Order',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Should VUE include your state's required disclosures on ",
            },
            {
              attributes: { bold: true },
              text: 'work orders',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        stateDisclosureRO: {
          questionKey: 'stateDisclosureRO',
          label: 'State Disclosure Repair Order',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Should VUE include your state's required disclosures on ",
            },
            {
              attributes: { bold: true },
              text: 'repair orders',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        stateDisclosureInternalRO: {
          questionKey: 'stateDisclosureInternalRO',
          label: 'State Disclosure Internal Repair Order',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Should VUE include your state's required disclosures on ",
            },
            {
              attributes: { bold: true },
              text: 'internal copies of repair orders',
            },
            {
              attributes: null,
              text: '?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['service-default'];
