import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseLog, ResponseOptions } from '@dominion/interfaces';
import { ResponseHistoryComponent } from './response-history.component';
import { ModalComponent } from '../shared/modal/modal.component';
import { SharedModule } from '../shared/shared.module';
import { IconHistoryComponent } from '../icons/icon-history.component';

@Component({
  selector: 'dominion-response-history-trigger',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ResponseHistoryComponent,
    IconHistoryComponent,
  ],
  template: `
    <div>
      <button
        type="button"
        (click)="logModal.open()"
        class="flex items-center gap-1 text-gray-500"
      >
        <dominion-icon-history size="16"></dominion-icon-history>
        <span class="text-sm">History</span>
      </button>

      <dominion-modal #logModal [title]="'Response History'">
        <dominion-response-history
          body
          [log]="log"
          [options]="options"
        ></dominion-response-history>
      </dominion-modal>
    </div>
  `,
})
export class ResponseHistoryTriggerComponent {
  @Input() log: ResponseLog[] = [];
  @Input() options?: ResponseOptions[] = [];

  @ViewChild('logModal') logModal: ModalComponent;
}
