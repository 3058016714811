<div class="flex flex-1 flex-row justify-center">
  <div class="flex w-full max-w-[500px] flex-col">
    @if (fileInputs && fileInputs.length > 0) {
      @for (input of fileInputs; track tracker(input)) {
        <dominion-input-file
          class="mt-2"
          [data]="input.fileReference"
          [companyId]="companyId"
          [moduleId]="moduleId"
          [submoduleId]="submoduleId"
          [questionKey]="questionKey"
          (delete)="handleDelete($event, input)"
          (upload)="handleUpload($event, input)"
        ></dominion-input-file>
      }
    }

    <!-- add a file input -->
    <div class="text-dms-mid-green mt-4 flex flex-row justify-center">
      <button class="hover:text-dms-green shrink" (click)="addFileInput()">
        &plus; Add Another File
      </button>
    </div>

    <div class="mt-8 flex items-center">
      @if (data && data.log.length > 0) {
        <dominion-response-history-trigger
          [log]="data.log"
        ></dominion-response-history-trigger>
      }

      <div class="flex-1"></div>

      <button
        dominionDiscoveryNextBtn
        type="button"
        (click)="advance()"
        [ngClass]="{ disabled: isDisabled() }"
      >
        Next
      </button>
    </div>
  </div>
</div>
