import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

export type TableSortOrder = 'ascending' | 'descending';

@Component({
  selector: 'dominion-sortable-table-header, [sortable-table-header]',
  standalone: true,
  templateUrl: './sortable-table-header.component.html',
})
export class SortableTableHeaderComponent<T extends string = string> {
  @Input() label!: string;
  @Input() field!: T;
  @Input() sortField: T;
  @Input() sortOrder: TableSortOrder = 'ascending';
  @Input() class: string = '';

  @Output() emitSort = new EventEmitter<{
    field: T;
    order: TableSortOrder;
  }>();

  @HostBinding('class')
  get classList(): string {
    return this.class;
  }

  @HostBinding('attr.aria-sort')
  get ariaSortOrder(): string | null {
    return this.isSorted ? this.sortOrder : null;
  }

  get isSorted(): boolean {
    return this.sortField === this.field;
  }

  toggleSort() {
    const nextOrder =
      this.isSorted && this.sortOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.emitSort.emit({ field: this.field, order: nextOrder });
  }
}
