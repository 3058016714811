import { Component, OnInit, ViewChild } from '@angular/core';
import { ICompanyReadBrief } from '@dominion/interfaces';
import { CompanyService } from '../../company/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterLink } from '@angular/router';
import { SearchBarComponent } from '../../shared/search-bar/search-bar.component';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { TableComponent } from '../../shared/table/table.component';
import {
  SortableTableHeaderComponent,
  TableSortOrder,
} from '../../shared/table/sortable-table-header/sortable-table-header.component';
import { CompanySortField } from 'libs/interfaces/src/lib/constants/sort-fields';

@Component({
  selector: 'dominion-admin-companies',
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    RouterLink,
    SearchBarComponent,
    IconGoToComponent,
    TableComponent,
    SortableTableHeaderComponent,
  ],
})
export class AdminCompaniesComponent implements OnInit {
  public companies: ICompanyReadBrief[] = [];
  public isLoadingCompanies = false;
  public page = 1;
  public companyCount = 0;
  public resultsPerPage = 25;
  public searchQuery = '';

  sortField: CompanySortField = 'name';
  sortOrder: TableSortOrder = 'ascending';

  get pages(): number {
    return Math.ceil(this.companyCount / this.resultsPerPage);
  }

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  constructor(private companyService: CompanyService) {}

  nextPage() {
    if (this.page < this.pages) {
      this.getCompanies(this.page + 1);
    }
  }

  previousPage() {
    if (this.page > 1) {
      this.getCompanies(this.page - 1);
    }
  }

  firstPage() {
    this.getCompanies(1);
  }

  lastPage() {
    this.getCompanies(this.pages);
  }

  public getCompanies(page: number, showLoading = true) {
    if (showLoading) {
      this.isLoadingCompanies = true;
    }
    this.companyService
      .getCompanies(page, this.searchQuery, this.sortField, this.sortOrder)
      .subscribe({
        next: (result) => {
          this.page = page;
          this.companyCount = result.count;
          this.companies = result.companies;
          this.isLoadingCompanies = false;
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          this.isLoadingCompanies = false;
        },
      });
  }

  onSort(sort: { field: CompanySortField; order: TableSortOrder }) {
    this.sortField = sort.field;
    this.sortOrder = sort.order;
    this.getCompanies(this.page, false);
  }

  ngOnInit(): void {
    this.getCompanies(1);
  }

  searchCompanies(value: string) {
    this.isLoadingCompanies = true;
    this.page = 1;
    this.searchQuery = value;
    this.companyService
      .getCompanies(this.page, value, this.sortField, this.sortOrder)
      .subscribe({
        next: (result) => {
          this.companyCount = result.count;
          this.companies = result.companies;
          this.isLoadingCompanies = false;
          this.searchBar.done();
        },
        error: (err: HttpErrorResponse) => {
          this.isLoadingCompanies = false;
          this.searchQuery = '';
          console.error(err);
        },
      });
  }
}
