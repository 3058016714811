import {
  IUserAuthorizations,
  IUserDocument,
  IVendorCustom,
  IStandardVendorCompanyRef,
  VendorAuthorization,
  IFileUploadReference,
  SubmoduleType,
  States,
  IVendorAuthRef,
} from '@dominion/interfaces';
import {
  ICoreModule,
  ICoreModuleSubdocument,
} from '../modules/core/module/core-module.interfaces';
import { IAddress, IDMS } from '@dominion/interfaces';
import { HydratedDocument, Model, Types } from 'mongoose';

export interface IInternalAssigned {
  projectmanager: string | null;
  deploymentmanager: string | null;
  deploymenttechnician: string | null;
}

/* CORE COMPANY */
export interface ICompany {
  _id: string;
  name: string;
  address: IAddress;
  dms: IDMS | string | null;
  preferredInstallDate: Date | null;
  contractExpirationDate: Date | null;
  modules: ICoreModule[];
  users: string[];
  hubspotId: string | null;
  dateCreated: Date;
  goLiveDate: Date | null;
  createdBy: 'hubspot' | 'user';
  parentCompany: string | null;
  svd: string | null;
  childCompanies: string[];
  invitedUsers: string[];
  internalAssigned: IInternalAssigned;
  customVendors: IVendorCustom[];
  standardVendors: IStandardVendorCompanyRef[];
  vendorAuthorizationSnapshots: VendorAuthorization[];
  documents: IFileUploadReference[];
}

//
//
// COMPANY MODEL

export interface ICompanyModelOverrides {
  _id: Types.ObjectId;
  modules: Types.DocumentArray<ICoreModuleSubdocument>;
  users: Types.Array<Types.ObjectId>;
  parentCompany: Types.ObjectId | null;
  childCompanies: Types.Array<Types.ObjectId>;
  invitedUsers: Types.Array<Types.ObjectId>;
  internalAssigned: Types.Array<Types.ObjectId>;
}

export interface ICompanyModel
  extends Model<ICompany, object, ICompanyModelOverrides> {}

export interface ICompanyDocument
  extends HydratedDocument<ICompany, ICompanyModelOverrides> {}

export interface ICompanyDocumentPop
  extends Omit<
    ICompanyDocument,
    | 'users'
    | 'childCompanies'
    | 'parentCompany'
    | 'invitedUsers'
    | 'internalAssigned'
  > {
  users: Types.DocumentArray<IUserDocument>;
  childCompanies: Types.DocumentArray<IChildCompanyDocPop>;
  parentCompany: ICompanyDocumentPop;
  invitedUsers: Types.DocumentArray<IUserDocument>;
  internalAssigned: Types.DocumentArray<IUserDocument>;
}

export interface IChildCompanyDocPop
  extends Omit<
    ICompanyDocument,
    | 'hubspotId'
    | 'createdBy'
    | 'dateCreated'
    | 'childCompanies'
    | 'parentCompany'
  > {
  childCompanies: Types.DocumentArray<Types.ObjectId>;
  parentCompany: Types.ObjectId;
}

/**/
/**/
/* CRUD DERIVATIVES */

/* CREATE */

type CompanyCreatePickKeys =
  | 'name'
  | 'address'
  | 'dms'
  | 'preferredInstallDate'
  | 'contractExpirationDate';
export interface ICompanyCreateDto
  extends Partial<Pick<ICompany, CompanyCreatePickKeys>> {
  name: ICompany['name'];
  address: ICompany['address'];
}

/* READ */

type ICompanyReadExternalBriefKeys =
  | 'name'
  | 'parentCompany'
  | 'childCompanies'
  | '_id';
export interface ICompanyReadExternalBrief
  extends Pick<ICompany, ICompanyReadExternalBriefKeys> {}

type ICompanyReadBriefOmitKeys = 'projects' | 'contacts';
export interface ICompanyReadBrief
  extends Omit<ICompany, ICompanyReadBriefOmitKeys> {}

export interface ICompanyReadBriefMeta {
  count: number;
  companies: ICompanyReadBrief[];
}

export interface ICompanyReadFull
  extends Omit<
    ICompany,
    | 'parentCompany'
    | 'childCompanies'
    | 'users'
    | 'invitedUsers'
    | 'internalAssigned'
  > {
  parentCompany: ICompanyReadFull | null;
  childCompanies: ICompanyReadBrief[];
  users: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    authorizations: IUserAuthorizations;
    isDeactivated: boolean;
  }[];
  invitedUsers: {
    _id: string;
    email: string;
    authorizations: IUserAuthorizations;
    invite: {
      invitedBy: string;
      invitedOn: Date;
    };
    isDeactivated: boolean;
  }[];
  internalAssigned: {
    projectmanager: {
      _id: string;
      firstName: string;
      lastName: string;
      email: string;
    } | null;
    deploymentmanager: {
      _id: string;
      firstName: string;
      lastName: string;
      email: string;
    } | null;
    deploymenttechnician: {
      _id: string;
      firstName: string;
      lastName: string;
      email: string;
    } | null;
  };
}

type ICompanyReadDetailOmitKeys =
  | 'hubspotId'
  | 'createdBy'
  | 'dateCreated'
  | 'childCompanies'
  | 'parentCompany';
export interface ICompanyReadDetailExternal
  extends Omit<ICompany, ICompanyReadDetailOmitKeys> {
  hubspotId?: undefined;
  createdBy?: undefined;
  dateCreated?: undefined;
  childCompanies: IChildCompanyReadDetailExternal[];
  parentCompany: ICompanyReadDetailExternal | null;
}

export interface IChildCompanyReadDetailExternal
  extends Omit<ICompanyReadDetailExternal, 'childCompanies' | 'parentCompany'> {
  parentCompany: string | null;
  childCompanies: string[];
}

export interface IGetCompanyWithSubmoduleDto {
  companyId: string;
  moduleId: string;
  submoduleType: SubmoduleType;
}

export interface IAddressDto {
  street: string;
  line2?: string;
  city: string;
  state: States;
  zip: string;
}

/* UPDATE */
type CompanyUpdatePickKeys =
  | 'name'
  | 'address'
  | 'dms'
  | 'preferredInstallDate'
  | 'contractExpirationDate';
export interface ICompanyUpdateDto
  extends Partial<Pick<ICompany, CompanyUpdatePickKeys>> {}
export interface ICompanyUpdateEvent {
  field: keyof ICompany | keyof IAddress;
  value: ICompany[keyof ICompany] | unknown;
}

export interface IAssignDominionToCompanyDto {
  userId: string;
  companyId: string;
}

export interface IAuthorizeVendorsDto {
  companyId: string;
  vendors: IVendorAuthRef[];
}
