import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ResponseInput } from '../input.interface';
import {
  IDiscoveryDataProperty,
  ResponseOptions,
  ResponseValidation,
} from '@dominion/interfaces';
import { ValidationService } from '../../services/input-validation.service';
import {
  Directive,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';

@Directive()
export abstract class BaseInputComponent
  implements OnInit, OnChanges, ResponseInput
{
  @ViewChildren(PopoverHostDirective)
  popoverHosts: QueryList<PopoverHostDirective>;

  data: IDiscoveryDataProperty<unknown>;
  limit?: number;
  validation?: ResponseValidation[] = [];
  form: FormGroup;
  errorMessage?: string;
  serverErrMsg?: string;

  abstract response: EventEmitter<any>;

  constructor(
    protected fb: FormBuilder,
    protected validationService: ValidationService,
  ) {
    this.form = this.fb.group({
      instances: this.fb.array([]),
    });
  }

  options?: ResponseOptions[] | undefined;

  ngOnInit(): void {
    this.setInitialValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.setInitialValues();
    }
    if (changes['validation'] && this.validation) {
      this.applyValidators();
    }
  }

  setInitialValues() {
    // Remove results that exceed the limit
    if (
      this.limit &&
      Array.isArray(this.data.value) &&
      this.data.value.length > this.limit
    ) {
      this.data.value = this.data.value.slice(0, this.limit);
    }

    this.instances.clear();

    if (Array.isArray(this.data.value)) {
      this.data.value.forEach((value) => {
        this.addInstance(value);
      });
    } else {
      const label = this.options?.find((o) => o.value === this.data.value)
        ?.label;
      if (label) {
        this.addInstance({ label, value: this.data.value });
      } else {
        this.addInstance(this.data.value);
      }
    }
  }

  get instances() {
    return this.form.get('instances') as FormArray;
  }

  get canAddInstance(): boolean {
    return !!(
      this.limit &&
      this.instances.length < this.limit &&
      this.instances.controls[this.instances.length - 1]?.valid
    );
  }

  get canRemoveInstances(): boolean {
    return this.instances.length > 1;
  }

  createInstanceControl(value: unknown) {
    const control = this.fb.control(value);
    control.setValidators(
      this.validationService.getComposedValidator(this.validation ?? []),
    );
    control.updateValueAndValidity();
    return control;
  }

  addInstance(value: unknown = '') {
    const newControl = this.createInstanceControl(value);
    this.instances.push(newControl);
  }

  removeInstance(index: number) {
    if (this.instances.length > 1) {
      this.instances.removeAt(index);
    }
  }

  applyValidators() {
    this.instances.controls.forEach((control) => {
      control.setValidators(
        this.validationService.getComposedValidator(this.validation ?? []),
      );
      control.updateValueAndValidity();
    });
  }

  save() {
    this.resetErrors();
    if (this.form.valid) {
      const instanceResponses: string[] = this.instances.controls.map(
        (control) => control.value,
      );
      if (typeof this.limit === 'undefined') {
        this.response.emit(instanceResponses[0]);
      } else {
        this.response.emit(instanceResponses);
      }
    } else {
      this.errorMessage = 'Please enter a valid response.';
      this.showPopovers();
    }
  }

  checkErrors() {
    if (this.form.invalid) {
      this.errorMessage = 'Please enter a valid response.';
      this.showPopovers();
    }
  }

  handleErr(err: HttpErrorResponse): void {
    this.serverErrMsg = err.error.message;
    this.showPopovers();
  }

  handleSuccess(): void {
    return;
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.errorMessage = undefined;
    this.hidePopovers();
  }

  private showPopovers() {
    this.popoverHosts.forEach((popoverHost, index) => {
      if (!this.instances.controls[index].valid) {
        popoverHost.show();
      }
    });
  }

  private hidePopovers() {
    this.popoverHosts.forEach((popoverHost) => popoverHost.hide());
  }
}
