<div class="flex flex-1 flex-row justify-center">
  <div class="flex w-full max-w-[500px] flex-col gap-4 px-9">
    <div [formGroup]="form" data-testid="editable-text-active">
      <div formArrayName="instances" class="flex flex-col gap-2">
        @for (
          control of instances.controls;
          track control;
          let index = $index
        ) {
          <div
            [formGroupName]="index"
            class="flex w-full items-center [--input-height:2rem]"
          >
            <div
              class="grid w-full grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)] items-center gap-4"
            >
              <div>
                @if ($first && componentConfig?.labels?.lhs; as lhsLabel) {
                  <label
                    [for]="'lhs-' + index"
                    class="mb-0.5 block text-sm text-gray-500"
                    >{{ lhsLabel }}</label
                  >
                }

                <div
                  dominionPopoverHost
                  class="focus-within:ring-dms-light-green flex h-[var(--input-height)] overflow-hidden rounded-sm border border-gray-300 ring-offset-1 focus-within:ring"
                >
                  @if (componentConfig?.prefix; as prefix) {
                    <div
                      class="flex items-center border-r border-gray-300 bg-gray-100 px-2 text-sm font-medium text-gray-500 antialiased"
                    >
                      {{ prefix }}
                    </div>
                  }
                  <input
                    dominionPopoverTarget
                    [id]="'lhs-' + index"
                    type="text"
                    formControlName="lhs"
                    class="flex h-full w-full bg-white px-2 py-1 text-sm outline-none"
                    (input)="resetErrors()"
                    (blur)="checkErrors()"
                    data-testid="editable-text-input"
                    [attr.aria-invalid]="control.get('lhs')?.invalid"
                  />
                  @if (componentConfig?.suffix; as suffix) {
                    <div
                      class="flex items-center border-l border-gray-300 bg-gray-100 px-2 text-sm font-medium text-gray-500 antialiased"
                    >
                      {{ suffix }}
                    </div>
                  }
                  <div
                    dominionPopover
                    class="flex flex-col gap-2 text-sm font-normal text-red-600"
                    data-testid="editable-text-popover"
                  >
                    @if (form.invalid) {
                      <p>{{ errorMessage }}</p>
                    }
                    @if (serverErrMsg) {
                      <p>{{ serverErrMsg }}</p>
                    }
                  </div>
                </div>
              </div>

              <div
                class="mt-auto h-[var(--input-height)] select-none leading-[var(--input-height)] text-gray-500"
              >
                &rarr;
              </div>

              <div>
                @if ($first && componentConfig?.labels?.rhs; as rhsLabel) {
                  <label class="mb-0.5 block text-sm text-gray-500">{{
                    rhsLabel
                  }}</label>
                }

                <div dominionPopoverHost class="h-[var(--input-height)]">
                  <div
                    dominionPopoverTarget
                    class="h-full rounded-sm border border-gray-300"
                  >
                    <dominion-in-situ-select
                      [allowNull]="false"
                      [config]="{
                        dropdownCaret: true,
                        searchable: false,
                        togglePaddingX: 'px-2',
                        togglePaddingY: 'h-full py-1'
                      }"
                      [options]="options"
                      [initialValue]="instances.at(index).get('rhs')?.value"
                      defaultValue="Select Option"
                      (closed)="checkErrors()"
                      (saved)="
                        resetErrors();
                        instances.at(index).get('rhs')?.setValue($event)
                      "
                      [isDisabled]="false"
                      class="[&>*]:w-full"
                    ></dominion-in-situ-select>
                  </div>
                </div>
              </div>
            </div>

            @if (canRemoveInstances) {
              <div
                class="mt-auto flex h-[var(--input-height)] items-center justify-center bg-blue-500/10 leading-[var(--input-height)]"
              >
                <dominion-remove-instance-button
                  [index]="index"
                  (onClick)="removeInstance(index)"
                />
              </div>
            }
          </div>
        }
      </div>
    </div>

    <div>
      @if (limit) {
        <dominion-add-instance-button
          [disabled]="!canAddInstance"
          (onClick)="addInstance()"
        />
      }
    </div>

    <div class="mt-8 flex items-center justify-between">
      @if (data && data.log.length > 0) {
        <dominion-response-history-trigger
          [log]="data.log"
          [options]="options"
        ></dominion-response-history-trigger>
      }

      <button
        dominionDiscoveryNextBtn
        type="submit"
        (click)="save()"
        [ngClass]="{ disabled: form.invalid }"
      >
        Next
      </button>
    </div>
  </div>
</div>
