<button
  (click)="toggleSort()"
  [attr.aria-label]="
    'Sort ' +
    label +
    ' by ' +
    (sortOrder === 'ascending' ? 'descending' : 'ascending')
  "
  class="uppercase"
>
  {{ label }}
</button>
