import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'dominion-add-instance-button',
  imports: [CommonModule, SharedModule],
  standalone: true,
  template: `
    <button
      type="button"
      (click)="onClick.emit()"
      [disabled]="disabled"
      class="text-dms-green group flex items-center gap-1 text-sm disabled:cursor-not-allowed disabled:text-gray-400"
      data-testid="add-instance-button"
    >
      <div>
        <dominion-icon-plus
          size="16"
          strokeColor="stroke-white"
          fillColor="group-disabled:fill-gray-400 fill-dms-green"
        >
        </dominion-icon-plus>
      </div>
      <span>Add response</span>
    </button>
  `,
})
export class AddInstanceButtonComponent {
  @Input() disabled = false;
  @Output() onClick = new EventEmitter<void>();
}
