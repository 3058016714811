<form
  [formGroup]="contactForm"
  (ngSubmit)="submit()"
  class="mx-auto max-w-md flex-1"
>
  <div class="flex flex-col gap-4">
    @if (sections.orgName.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Name"
        [required]="!!sections.orgName.required"
        [formGroup]="contactForm"
        formControlName="orgName"
      ></dominion-form-input>
    }

    @if (sections.orgAddress.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Address"
        [required]="!!sections.orgAddress.required"
        [formGroup]="contactForm"
        formControlName="orgAddress"
      ></dominion-form-input>
    }

    @if (sections.orgEmail.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Email"
        [required]="!!sections.orgEmail.required"
        [formGroup]="contactForm"
        formControlName="orgEmail"
      ></dominion-form-input>
    }

    @if (sections.orgPhone.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Phone"
        [required]="!!sections.orgPhone.required"
        [formGroup]="contactForm"
        formControlName="orgPhone"
      ></dominion-form-input>
    }

    @if (sections.contactName.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Primary Contact Name"
        [required]="!!sections.contactName.required"
        [formGroup]="contactForm"
        formControlName="contactName"
      ></dominion-form-input>
    }

    @if (sections.notes.enabled) {
      <dominion-form-input
        ngDefaultControl
        type="textarea"
        label="Notes"
        [required]="!!sections.notes.required"
        [formGroup]="contactForm"
        formControlName="notes"
      ></dominion-form-input>
    }
  </div>

  <div class="mt-8 flex items-center justify-between">
    @if (data && data.log.length > 0) {
      <dominion-response-history-trigger
        [log]="data.log"
      ></dominion-response-history-trigger>
    }

    <div class="flex-1"></div>

    <button
      type="submit"
      dominionDiscoveryNextBtn
      [ngClass]="{ disabled: contactForm.invalid }"
      [disabled]="contactForm.invalid"
    >
      Next
    </button>
  </div>
</form>
