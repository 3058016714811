<form
  dominionPopoverHost
  [formGroup]="form"
  class="mx-auto w-full max-w-[500px] antialiased"
>
  <fieldset class="mx-auto flex items-center justify-center gap-3">
    <legend class="sr-only">Time</legend>

    <div dominionPopoverTarget class="flex items-center gap-1.5">
      <div>
        <label for="hour" class="sr-only">Hour</label>
        <input
          #hourInputEl
          data-testid="hour-input"
          dominionAutofocus
          [enableAutofocus]="isCurrentQuestion"
          formControlName="hour"
          id="hour"
          type="number"
          placeholder="8"
          min="1"
          max="12"
          maxlength="2"
          (keydown)="onKeydown($event, 'hour')"
          (input)="onInput('hour')"
          (blur)="checkErr()"
          class="focus:border-dms-light-green focus:border-dms-green/30 focus:ring-dms-green/20 h-10 w-full max-w-[6ch] rounded border px-1.5 text-center font-medium transition-colors focus:outline-none focus:ring-2"
        />
      </div>

      <div class="select-none pb-0.5 font-semibold text-gray-400">:</div>

      <div>
        <label for="minute" class="sr-only">Minute</label>
        <input
          #minuteInputEl
          data-testid="minute-input"
          formControlName="minute"
          id="minute"
          type="number"
          placeholder="30"
          min="0"
          max="59"
          maxlength="2"
          (keydown)="onKeydown($event, 'minute')"
          (input)="onInput('minute')"
          (blur)="checkErr()"
          class="focus:border-dms-light-green focus:border-dms-green/30 focus:ring-dms-green/20 h-10 w-full max-w-[6ch] rounded border px-1.5 text-center font-medium transition-colors focus:outline-none focus:ring-2"
        />
      </div>
    </div>

    <button
      #meridiemInputEl
      data-testid="meridiem-toggle"
      type="button"
      role="radiogroup"
      aria-label="Meridiem"
      [attr.aria-description]="
        form.get('meridiem')?.value + ' currently selected'
      "
      (click)="toggleMeridiem()"
      (keydown)="onKeydown($event, 'meridiem')"
      class="focus:ring-dms-green/20 focus:border-dms-green/30 relative flex h-10 gap-0.5 rounded border bg-gray-50 p-0.5 transition-colors hover:bg-gray-100/80 focus:outline-none focus:ring-2"
    >
      <div
        class="bg-dms-light-green/20 absolute inset-y-0.5 w-[calc(50%-3px)] rounded-sm transition-all duration-150"
        [ngClass]="
          form.get('meridiem')?.value === 'PM'
            ? 'left-[calc(50%+1px)]'
            : 'left-0.5'
        "
      ></div>

      <div
        role="radio"
        [attr.aria-checked]="form.get('meridiem')?.value === 'AM'"
        class="aria-checked:text-dms-green flex h-full items-center justify-center rounded-sm px-1.5 text-sm font-semibold tracking-wide text-gray-400 transition-colors"
      >
        AM
      </div>

      <div
        role="radio"
        [attr.aria-checked]="form.get('meridiem')?.value === 'PM'"
        class="aria-checked:text-dms-green flex h-full items-center justify-center rounded-sm px-1.5 text-sm font-semibold tracking-wide text-gray-400 transition-colors"
      >
        PM
      </div>
    </button>
  </fieldset>

  <div dominionPopover class="text-sm font-normal text-red-600">
    <ng-container *ngIf="clientErrMsg">
      {{ clientErrMsg }}
    </ng-container>
    <ng-container *ngIf="serverErrMsg">
      <ng-container *ngIf="clientErrMsg"><br /></ng-container>
      {{ serverErrMsg }}
    </ng-container>
  </div>

  <div class="mt-8 flex items-center">
    @if (data && data.log.length > 0) {
      <dominion-response-history-trigger
        [log]="data.log"
      ></dominion-response-history-trigger>
    }

    <div class="flex-1"></div>

    <button
      dominionDiscoveryNextBtn
      type="submit"
      [disabled]="form.invalid"
      (click)="save()"
      [ngClass]="{ disabled: form.invalid }"
    >
      Next
    </button>
  </div>
</form>
