import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { IconDeleteComponent } from '../../icons/icon-delete.component';

@Component({
  selector: 'dominion-remove-instance-button',
  imports: [CommonModule, SharedModule, IconDeleteComponent],
  standalone: true,
  template: `
    <button
      type="button"
      title="Delete response"
      (click)="onClick.emit()"
      class="-mr-10 ml-3 flex h-7 w-7 shrink-0 items-center justify-center rounded-full border border-gray-300 bg-gray-50 text-gray-500 shadow-sm transition hover:border-red-600/20 hover:bg-red-100 hover:text-red-600 focus:border-red-600/20 focus:bg-red-100 focus:text-red-600 focus:outline-none focus:ring focus:ring-red-600/40 focus:ring-offset-1"
      [attr.data-testid]="'remove-instance-button-' + index"
    >
      <div class="size-4">
        <dominion-icon-delete
          strokeColor="stroke-current"
        ></dominion-icon-delete>
      </div>
      <span class="sr-only">Delete response</span>
    </button>
  `,
})
export class RemoveInstanceButtonComponent {
  @Input() index = 0;
  @Output() onClick = new EventEmitter<void>();
}
