import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseLog, ResponseOptions } from '@dominion/interfaces';
import { ResponseDataPipe } from '../shared/pipes/response-data.pipe';

interface DisplayResponseLog extends ResponseLog {
  formattedValue: string | null;
}

@Component({
  selector: 'dominion-response-history',
  standalone: true,
  imports: [CommonModule],
  providers: [ResponseDataPipe],
  templateUrl: './response-history.component.html',
})
export class ResponseHistoryComponent implements OnChanges {
  @Input() log: ResponseLog[] = [];
  @Input() options?: ResponseOptions[] = [];

  filteredEntries: DisplayResponseLog[] = [];

  constructor(private responseDataPipe: ResponseDataPipe) {}

  ngOnChanges() {
    this.updateFilteredEntries();
  }

  private updateFilteredEntries() {
    this.filteredEntries = this.log
      .filter((entry, index, log) => {
        const nextEntry = log[index + 1];
        return (
          !nextEntry ||
          JSON.stringify(entry.value) != JSON.stringify(nextEntry.value)
        );
      })
      .map((entry) => ({
        ...entry,
        formattedValue: this.responseDataPipe.transform(
          // Use value.value if available, indicating a legacy response
          entry.value?.value ?? entry.value,
          this.options,
        ),
      }));
  }
}
