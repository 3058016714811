<div class="flex h-full w-full flex-col">
  <div class="flex flex-row items-center p-4">
    <div dominionCoreOutletTitle>Vendors</div>
    <div class="ml-2 flex">
      <dominion-core-create-btn
        data-testid="create-vendor-btn"
        (click)="openAddVendorModal()"
      >
      </dominion-core-create-btn>
    </div>
  </div>

  <div class="flex-1 overflow-hidden p-4">
    <div class="flex h-full w-full flex-col">
      <div class="flex flex-wrap justify-between gap-4">
        <div class="flex gap-4">
          <dominion-option-toggle
            [selectedOption]="selectedView"
            (emitSelectedOption)="setSelectedView($event)"
            [options]="viewToggleOptions"
          ></dominion-option-toggle>
          @if (selectedView.value === 'question') {
            <dominion-option-toggle
              [selectedOption]="selectedModuleGroup"
              [selectedBgColor]="'[&.selected]:bg-dms-teal'"
              [options]="vendorQuestionToggleOptions"
              (emitSelectedOption)="selectVendorQuestionGroup($event)"
            ></dominion-option-toggle>
          }
          @if (selectedView.value === 'vendor') {
            <div
              class="flex flex-row items-center text-sm font-light text-gray-600"
            >
              {{ vendorCount }}
              {{ vendorCount === 1 ? 'vendor' : 'vendors' }}
            </div>
          }
        </div>

        @if (selectedView.value === 'vendor') {
          <dominion-search-bar
            (emitStartSearch)="isLoadingVendors = true"
            (emitFilterValue)="searchVendors($event)"
            class="w-full min-w-max max-w-xs"
          ></dominion-search-bar>
        }
      </div>

      <div class="h-6"></div>

      @if (selectedView.value === 'vendor') {
        <dominion-table
          [isLoading]="isLoadingVendors"
          [isEmpty]="filteredVendors.length === 0"
          [fixed]="true"
        >
          <thead>
            <tr>
              <th
                sortable-table-header
                label="Vendor Name"
                field="providerName"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                (emitSort)="onSort($event)"
                class="w-64"
              ></th>
              <th
                sortable-table-header
                label="Application Name"
                field="appName"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                (emitSort)="onSort($event)"
                class="w-64"
              ></th>
              <th scope="col" class="w-20">
                <span class="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (vendor of filteredVendors; track vendor._id) {
              <tr
                (click)="openUpdateVendorModal(vendor)"
                class="cursor-pointer"
              >
                <td>
                  @if (vendor.providerName) {
                    <span class="font-semibold">{{ vendor.providerName }}</span>
                  } @else {
                    <span class="text-gray-400">&mdash;</span>
                  }
                </td>
                <td>
                  @if (vendor.appName) {
                    {{ vendor.appName }}
                  } @else {
                    <span class="text-gray-400">&mdash;</span>
                  }
                </td>
                <td align="right">
                  <button
                    (click)="openUpdateVendorModal(vendor)"
                    class="text-dms-green hover:bg-dms-light-green/20 group h-7 w-7 cursor-pointer truncate rounded-sm p-[6px] text-sm font-medium"
                  >
                    <dominion-icon-go-to
                      strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
                    ></dominion-icon-go-to>
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </dominion-table>
      } @else if (selectedView.value === 'question') {
        <div
          *ngIf="selectedView.value === 'question'"
          class="flex-1 overflow-hidden p-4"
        >
          <div class="h-full w-full overflow-hidden">
            <dominion-vendor-questions
              [selectedModuleGroup]="selectedModuleGroup"
              [vendors]="vendors"
              [vendorQuestions]="vendorQuestions"
            ></dominion-vendor-questions>
          </div>
        </div>
      }
    </div>

    <dominion-modal #addVendorModal title="Add Vendor">
      <form
        body
        [formGroup]="addVendorForm"
        (ngSubmit)="addVendor()"
        class="flex w-full flex-col gap-6"
      >
        <dominion-form-input
          ngDefaultControl
          id="providerName"
          label="Vendor Name"
          placeholder="Vision Dealer Solutions"
          [formGroup]="addVendorForm"
          formControlName="providerName"
          [required]="true"
          errorStyle="below"
          data-testid="addVendorForm-providerName"
        ></dominion-form-input>

        <dominion-form-input
          ngDefaultControl
          id="appName"
          label="Application Name"
          placeholder="VisionMenu"
          [formGroup]="addVendorForm"
          formControlName="appName"
          [required]="true"
          errorStyle="below"
          data-testid="addVendorForm-appName"
        ></dominion-form-input>

        <div class="flex items-center justify-end gap-4">
          <button
            type="button"
            (click)="closeAddVendorModal()"
            class="h-9 rounded border bg-gray-50 px-3 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-950"
          >
            Cancel
          </button>

          <button
            type="submit"
            class="bg-dms-teal flex h-9 items-center gap-2 rounded px-3 text-sm text-white disabled:cursor-not-allowed"
            [ngClass]="{
              'disabled:bg-dms-mid-green disabled:text-white': isAddingVendor,
              'disabled:bg-dms-light-grey disabled:text-gray-400':
                !isAddingVendor
            }"
            [disabled]="!addVendorForm.valid"
          >
            @if (isAddingVendor) {
              <dominion-load-spinner
                class="h-4 w-4 fill-none stroke-current"
              ></dominion-load-spinner>
            } @else {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="h-4 w-4"
              >
                <path
                  d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z"
                />
              </svg>
            }
            <span class="pr-1">Add Vendor</span>
          </button>
        </div>
      </form>
    </dominion-modal>

    <dominion-modal #updateVendorModal title="Update Vendor">
      <form
        body
        [formGroup]="updateVendorForm"
        (ngSubmit)="updateVendor()"
        class="flex w-full flex-col gap-6"
      >
        <dominion-form-input
          ngDefaultControl
          id="providerName"
          label="Vendor Name"
          placeholder="Vision Dealer Solutions"
          [formGroup]="updateVendorForm"
          formControlName="providerName"
          [required]="true"
          errorStyle="below"
          data-testid="updateVendorForm-providerName"
        ></dominion-form-input>

        <dominion-form-input
          ngDefaultControl
          id="appName"
          label="Application Name"
          placeholder="VisionMenu"
          [formGroup]="updateVendorForm"
          formControlName="appName"
          [required]="true"
          errorStyle="below"
          data-testid="updateVendorForm-appName"
        ></dominion-form-input>

        <div class="flex items-center justify-end gap-4">
          <button
            type="button"
            (click)="closeUpdateVendorModal()"
            class="h-9 rounded border bg-gray-50 px-3 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-950"
          >
            Cancel
          </button>

          <button
            type="submit"
            class="bg-dms-teal flex h-9 items-center gap-2 rounded px-3 text-sm text-white disabled:cursor-not-allowed"
            [ngClass]="{
              'disabled:bg-dms-mid-green disabled:text-white': isUpdatingVendor,
              'disabled:bg-dms-light-grey disabled:text-gray-400':
                !isUpdatingVendor
            }"
            [disabled]="!updateVendorForm.valid"
          >
            @if (isUpdatingVendor) {
              <dominion-load-spinner
                class="h-4 w-4 fill-none stroke-current"
              ></dominion-load-spinner>
            } @else {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="h-4 w-4"
              >
                <path
                  d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z"
                />
                <path
                  d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z"
                />
              </svg>
            }
            <span class="pr-1">Update Vendor</span>
          </button>
        </div>
      </form>
    </dominion-modal>
  </div>
</div>
