<div class="flex flex-1 flex-row justify-center">
  <div class="flex w-full max-w-[500px] flex-col gap-4 px-9">
    <div [formGroup]="form">
      <div formArrayName="instances" class="flex flex-col gap-2">
        @for (
          control of instances.controls;
          track control;
          let index = $index
        ) {
          <div [formGroupName]="index" class="flex items-center">
            <div class="w-full">
              <dominion-dropdown-search
                [options]="options"
                [selectedOption]="control.value"
                (emitSelectedValue)="select($event, index)"
              ></dominion-dropdown-search>
            </div>

            @if (canRemoveInstances) {
              <dominion-remove-instance-button
                (onClick)="removeInstance(index)"
                [index]="index"
              ></dominion-remove-instance-button>
            }
          </div>
        }
      </div>
    </div>

    @if (limit) {
      <dominion-add-instance-button
        (onClick)="addInstance()"
        [disabled]="!canAddInstance"
      ></dominion-add-instance-button>
    }

    <div class="mt-8 flex items-center">
      @if (data && data.log.length > 0) {
        <dominion-response-history-trigger
          [log]="data.log"
          [options]="options"
        ></dominion-response-history-trigger>
      }

      <div class="flex-1"></div>

      <button
        dominionDiscoveryNextBtn
        type="submit"
        (click)="save()"
        [ngClass]="{ disabled: form.invalid }"
      >
        Next
      </button>
    </div>
  </div>
</div>
