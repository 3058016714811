import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import {
  ContactSections,
  IContact,
  IDiscoveryDataProperty,
  QuestionSections,
  SectionOption,
} from '@dominion/interfaces';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { FormInputComponent } from '../../form-input/form-input.component';
import { ResponseHistoryTriggerComponent } from '../../response-history/response-history-trigger.component';

@Component({
  selector: 'dominion-input-contact',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormInputComponent,
    ResponseHistoryTriggerComponent,
  ],
  templateUrl: './input-contact.component.html',
  styleUrls: ['./input-contact.component.css'],
})
export class InputContactComponent implements ResponseInput, OnChanges {
  private _sections: Required<ContactSections> =
    {} as Required<ContactSections>;

  @Input({ required: true }) data: IDiscoveryDataProperty<IContact | string>;

  @Input()
  set sections(value: QuestionSections) {
    if (!value) {
      return;
    }
    const defaults: Required<SectionOption> = { enabled: true, required: true };
    const keys: (keyof ContactSections)[] = [
      'orgName',
      'orgAddress',
      'orgEmail',
      'orgPhone',
      'contactName',
      'notes',
    ];
    const result: Required<ContactSections> = {} as Required<ContactSections>;

    for (const key of keys) {
      result[key] = { ...defaults, ...value[key] };
    }
    this._sections = result;

    // Show the 'notes' section if the value is a non-empty string or an object
    // with a non-empty 'notes' field and 'notes' is not defined in the
    // question interface.
    if (
      (typeof this.data.value === 'string' && this.data.value !== '') ||
      (this.data.value &&
        typeof this.data.value.notes === 'string' &&
        this.data.value.notes !== '' &&
        this._sections.notes === undefined)
    ) {
      this._sections.notes.enabled = true;
      this._sections.notes.required = false;
    }
    this.initializeForm();
  }
  get sections(): Required<ContactSections> {
    return this._sections;
  }

  @Output() response: EventEmitter<IContact> = new EventEmitter<IContact>();

  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] || changes['data.value']) {
      if (typeof this.data.value === 'string') {
        this.contactForm.patchValue({ notes: this.data.value });
      } else {
        this.contactForm.patchValue(this.data.value!);
      }
    }
  }

  private initializeForm() {
    const formControls: { [key: string]: any } = {};

    for (const [key, section] of Object.entries(this.sections)) {
      if (section.enabled) {
        formControls[key] = ['', section.required ? [Validators.required] : []];
      }
    }

    this.contactForm = this.fb.group(formControls);
  }

  submit() {
    this.response.emit(this.contactForm.value);
  }

  handleErr(): void {
    throw new Error('Method not implemented.');
  }

  handleSuccess(): void {
    return;
  }
}
