import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { Month } from '../../../utility/month.interfaces';
import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TAccountingPrelimGroupIdentifiers =
  | 'groupFiscalYear'
  | 'groupForecasting'
  | 'groupGeneralLedger'
  | 'groupIds'
  | 'groupMisc'
  | 'groupMulti'
  | 'groupOEMPayables'
  | 'groupPositivePay'
  | 'groupAccountingCompliance'
  | 'groupUsedFloorPlan'
  | 'groupAccountsReceivable'
  | 'groupVendors';

export type TAccountingPrelimDataKeys =
  | 'hasUsedFloorPlan'
  | 'usedFloorPlanAPR'
  | 'hasUsedFreeDays'
  | 'fiscalYearEndMonth'
  | 'fileEmployeeCustNonMatchExample'
  | 'useVUEPayroll'
  | 'fileRTD'
  | 'usesPreprintedARStatements'
  | 'fileARStatementExample'
  | 'yearEndProcess'
  | 'isForecasting'
  | 'forecastingAccountNumbers'
  | 'hasManagementCompany'
  | 'managedInDMSNow'
  | 'managedInDMSFuture'
  | 'numberCorporations'
  | 'fein'
  | 'stateTaxId'
  | 'manufacturerDealerCode'
  | 'efile1099'
  | 'irsTCC'
  | 'timeOnRetiringDMS'
  | 'chartOfAccounts'
  | 'paysVendorsOnline'
  | 'vendorNames'
  | 'printers'
  | 'pcPrintCount'
  | 'employeeCustomerNumbers'
  | 'hasMultipleDealerCodes'
  | 'canViewOtherInventory'
  | 'shareAssets'
  | 'howMoveCarsFinancially'
  | 'hasConsolidatedFinancialStatement'
  | 'multiOEMGL'
  | 'manualOEMPayableProcess'
  | 'OEMPayableGL'
  | 'OEMPayablesIsMainGL'
  | 'OEMPayablesVendorControl'
  | 'usesPositivePay'
  | 'accountingElectronicTitlingVendor'
  | 'electronicTitlingProcess'
  | 'accountingSignaturePadVendor'
  | 'documentScanningVendor'
  | 'useRealTimeReceiptPosting'
  | 'receiptClearingAccount'
  | 'receiptClearingJournal'
  | 'forecastingPeriod';

const accountingPrelimGroupMap = {
  moduleType: 'accounting',
  submoduleType: 'preliminary',
  discriminator: 'accounting-preliminary',
  groups: {
    groupUsedFloorPlan: [
      'hasUsedFloorPlan',
      'usedFloorPlanAPR',
      'hasUsedFreeDays',
    ],
    groupAccountsReceivable: [
      'usesPreprintedARStatements',
      'fileARStatementExample',
    ],
    groupFiscalYear: ['fiscalYearEndMonth', 'yearEndProcess'],
    groupForecasting: [
      'isForecasting',
      'forecastingPeriod',
      'forecastingAccountNumbers',
    ],
    groupGeneralLedger: [
      'hasManagementCompany',
      'managedInDMSFuture',
      'managedInDMSNow',
    ],
    groupIds: [
      'numberCorporations',
      'fein',
      'stateTaxId',
      'manufacturerDealerCode',
      'efile1099',
      'irsTCC',
    ],
    groupMulti: [
      'hasMultipleDealerCodes',
      'canViewOtherInventory',
      'shareAssets',
      'howMoveCarsFinancially',
      'hasConsolidatedFinancialStatement',
      'multiOEMGL',
    ],
    groupOEMPayables: [
      'manualOEMPayableProcess',
      'OEMPayableGL',
      'OEMPayablesIsMainGL',
      'OEMPayablesVendorControl',
    ],
    groupPositivePay: ['usesPositivePay'],
    groupAccountingCompliance: [
      'useRealTimeReceiptPosting',
      'receiptClearingAccount',
      'receiptClearingJournal',
    ],
    groupVendors: [
      'accountingElectronicTitlingVendor',
      'accountingSignaturePadVendor',
      'documentScanningVendor',
    ],
    groupMisc: [
      'timeOnRetiringDMS',
      'chartOfAccounts',
      'fileEmployeeCustNonMatchExample',
      'paysVendorsOnline',
      'vendorNames',
      'pcPrintCount',
      'printers',
      'employeeCustomerNumbers',
      'electronicTitlingProcess',
      'useVUEPayroll',
      'fileRTD',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TAccountingPrelimGroupIdentifiers,
  TAccountingPrelimDataKeys
>;

export type TAccountingPrelimGroupMap = typeof accountingPrelimGroupMap;

export class AccountingPreliminaryData
  implements TDataDefinition<TAccountingPrelimDataKeys>
{
  // FISCAL YEAR
  // groupFiscalYear
  fiscalYearEndMonth = new DataInitializerBasic<Month>();
  yearEndProcess = new DataInitializerBasic<string>();

  // FORECASTING
  // groupForecasting
  isForecasting = new DataInitializerBasic<boolean>();
  forecastingPeriod = new DataInitializerBasic<'monthly' | 'annually'>();
  forecastingAccountNumbers = new DataInitializerBasic<string>();

  // GL
  // groupGL
  hasManagementCompany = new DataInitializerBasic<boolean>();
  managedInDMSFuture = new DataInitializerBasic<boolean>();
  managedInDMSNow = new DataInitializerBasic<boolean>();

  // IDS
  // groupIds
  numberCorporations = new DataInitializerBasic<number>();
  fein = new DataInitializerBasic<string>();
  stateTaxId = new DataInitializerBasic<string>();
  manufacturerDealerCode = new DataInitializerBasic<string>();
  irsTCC = new DataInitializerBasic<string>();
  efile1099 = new DataInitializerBasic<boolean>();

  // MISC
  // groupMisc
  timeOnRetiringDMS = new DataInitializerBasic<string>();
  chartOfAccounts = new DataInitializerBasic<IFileUploadReference[]>([]);
  paysVendorsOnline = new DataInitializerBasic<boolean>();
  vendorNames = new DataInitializerBasic<string>();
  pcPrintCount = new DataInitializerBasic<number>();
  printers = new DataInitializerBasic<string>();
  employeeCustomerNumbers = new DataInitializerBasic<boolean>();
  useVUEPayroll = new DataInitializerBasic<boolean>();
  fileRTD = new DataInitializerBasic<IFileUploadReference[]>([]);
  fileEmployeeCustNonMatchExample = new DataInitializerBasic<
    IFileUploadReference[]
  >([]);

  // MULTI
  // groupMulti
  hasMultipleDealerCodes = new DataInitializerBasic<boolean>();
  canViewOtherInventory = new DataInitializerBasic<boolean>();
  shareAssets = new DataInitializerBasic<boolean>();
  howMoveCarsFinancially = new DataInitializerBasic<string>();
  hasConsolidatedFinancialStatement = new DataInitializerBasic<boolean>();
  multiOEMGL = new DataInitializerBasic<boolean>();

  // OEM PAYABLES
  // groupOEMPayables
  manualOEMPayableProcess = new DataInitializerBasic<boolean>();
  OEMPayableGL = new DataInitializerBasic<string>();
  OEMPayablesIsMainGL = new DataInitializerBasic<boolean>();
  OEMPayablesVendorControl = new DataInitializerBasic<boolean>();

  // POSITIVE PAY
  // groupPositivePay
  usesPositivePay = new DataInitializerBasic<boolean>();

  // VENDORS
  // groupVendors
  accountingElectronicTitlingVendor = new DataInitializerBasic<string>();
  electronicTitlingProcess = new DataInitializerBasic<string>();
  accountingSignaturePadVendor = new DataInitializerBasic<string>();
  documentScanningVendor = new DataInitializerBasic<string>();

  // ACCOUNTING COMPLIANCE
  // groupAccountingCompliance
  useRealTimeReceiptPosting = new DataInitializerBasic<boolean>();
  receiptClearingAccount = new DataInitializerBasic<string>();
  receiptClearingJournal = new DataInitializerBasic<string>();
  cashClearingAccount = new DataInitializerBasic<string>();

  // ACCOUNTS RECEIVABLE
  // groupAccountsReceivable
  usesPreprintedARStatements = new DataInitializerBasic<boolean>();
  fileARStatementExample = new DataInitializerBasic<IFileUploadReference[]>([]);

  hasUsedFloorPlan = new DataInitializerBasic<boolean>();
  usedFloorPlanAPR = new DataInitializerBasic<string>();
  hasUsedFreeDays = new DataInitializerBasic<boolean>();
}

export const accountingPreliminaryStructure = {
  moduleType: 'accounting',
  submoduleType: 'preliminary',
  discriminator: 'accounting-preliminary',
  groups: {
    groupFiscalYear: {
      groupLabel: 'Fiscal Year',
      groupType: 'standard',
      groupUrl: 'groupFiscalYear',
      questions: {
        fiscalYearEndMonth: {
          questionKey: 'fiscalYearEndMonth',
          label: 'Fiscal Year End Month',
          order: 1,
          prompt: [
            {
              text: 'What month does your fiscal year end?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'dropdown',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'January',
              value: 'january',
            },
            {
              label: 'February',
              value: 'february',
            },
            {
              label: 'March',
              value: 'march',
            },
            {
              label: 'April',
              value: 'april',
            },
            {
              label: 'May',
              value: 'may',
            },
            {
              label: 'June',
              value: 'june',
            },
            {
              label: 'July',
              value: 'july',
            },
            {
              label: 'August',
              value: 'august',
            },
            {
              label: 'September',
              value: 'september',
            },
            {
              label: 'October',
              value: 'october',
            },
            {
              label: 'November',
              value: 'november',
            },
            {
              label: 'December',
              value: 'december',
            },
          ],
          validation: [],
          dependencies: [],
        },
        yearEndProcess: {
          questionKey: 'yearEndProcess',
          label: 'Year End Process',
          order: 2,
          prompt: [
            {
              text: 'Please explain your year end process.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'Do you use a 13th-month financial statement to complete year-end? Please provide any other important details.',
              attributes: null,
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupAccountsReceivable: {
      groupLabel: 'Accounts Receivable',
      groupType: 'standard',
      groupUrl: 'groupAccountsReceivable',
      questions: {
        usesPreprintedARStatements: {
          questionKey: 'usesPreprintedARStatements',
          label: 'Uses Preprinted AR Statements',
          prompt: [
            {
              text: 'Do you use preprinted accounts receivable statements?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['fileARStatementExample'],
            },
          ],
        },
        fileARStatementExample: {
          questionKey: 'fileARStatementExample',
          label: 'AR Statement Example',
          prompt: [
            {
              text: 'Please provide an example of your preprinted accounts receivable statement.',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupForecasting: {
      groupLabel: 'Forecasting',
      groupType: 'standard',
      groupUrl: 'groupForecasting',
      questions: {
        isForecasting: {
          questionKey: 'isForecasting',
          label: 'Forecasting',
          order: 1,
          prompt: [
            {
              text: 'Do you want to forecast any of your general ledger accounts, such as sales or expense accounts?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'If yes, our client onboarding team will be in touch to ensure that these forecasts are properly configured.',
              attributes: null,
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['forecastingPeriod', 'forecastingAccountNumbers'],
            },
          ],
        },
        forecastingPeriod: {
          questionKey: 'forecastingPeriod',
          label: 'Forecasting Period',
          prompt: [
            {
              attributes: null,
              text: 'Would you like to forecast monthly or annually?',
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Monthly',
              value: 'monthly',
            },
            {
              label: 'Annually',
              value: 'annually',
            },
          ],
          validation: [],
          dependencies: [],
        },
        forecastingAccountNumbers: {
          questionKey: 'forecastingAccountNumbers',
          label: 'Forecasting Account Numbers',
          prompt: [
            {
              text: 'Please list each general ledger account number that you forecast.',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupGeneralLedger: {
      groupLabel: 'Management Company',
      groupType: 'standard',
      groupUrl: 'groupGeneralLedger',
      questions: {
        hasManagementCompany: {
          questionKey: 'hasManagementCompany',
          label: 'Management Company',
          prompt: [
            {
              text: 'Do you have a separate accounting management company?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['managedInDMSNow', 'managedInDMSFuture'],
            },
          ],
        },
        managedInDMSNow: {
          questionKey: 'managedInDMSNow',
          label: 'Managed in DMS Now',
          prompt: [
            {
              text: 'Do you manage your management company in your current DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        managedInDMSFuture: {
          questionKey: 'managedInDMSFuture',
          label: 'Managed in DMS Future',
          prompt: [
            {
              text: 'Do you want to manage your management company in VUE?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupIds: {
      groupLabel: 'Identification Numbers and Codes',
      groupType: 'standard',
      groupUrl: 'groupIds',
      questions: {
        numberCorporations: {
          questionKey: 'numberCorporations',
          label: 'Number of Corporations',
          order: 1,
          prompt: [
            {
              text: 'How many corporations/tax IDs do you have?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        fein: {
          questionKey: 'fein',
          label: 'Federal Tax ID Number',
          order: 2,
          prompt: [
            {
              text: 'What is your federal employer identification number (',
              attributes: null,
            },
            {
              text: 'FEIN',
              attributes: {
                tooltip: [
                  {
                    text: 'Sometimes called your federal tax ID number',
                    attributes: null,
                  },
                ],
              },
            },
            {
              text: ')?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        stateTaxId: {
          questionKey: 'stateTaxId',
          label: 'State Tax ID Number',
          order: 3,
          prompt: [
            {
              text: 'What is the state tax ID number for the dealership? If you have more than one, please list them all.',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        manufacturerDealerCode: {
          questionKey: 'manufacturerDealerCode',
          label: 'Manufacturer Dealer Code',
          order: 4,
          prompt: [
            {
              text: 'Please list all of your manufacturer dealer codes.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each code, please include the OEM name and the code.',
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        efile1099: {
          questionKey: 'efile1099',
          label: 'E-File 1099',
          order: 5,
          prompt: [
            {
              text: 'Do you e-file your 1099s?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['irsTCC'],
            },
          ],
        },
        irsTCC: {
          questionKey: 'irsTCC',
          label: 'Transmitter Code',
          order: 5,
          prompt: [
            {
              text: 'What is your Transmitter Control Code (TCC) for e-filing Forms 1099 with the IRS?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'The TCC is a 5-character code containing 2 digits followed by 1 letter followed by 2 digits. Please note that this is a ',
            },
            {
              attributes: { bold: true },
              text: 'different TCC ',
            },
            {
              attributes: null,
              text: 'than the one used to file Affordable Care Act (ACA) Forms 1094/1095-B and 1094/1095-C.',
            },
          ],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupMulti: {
      groupLabel: 'Multiple Dealerships or OEMs',
      groupType: 'standard',
      groupUrl: 'groupMulti',
      questions: {
        hasMultipleDealerCodes: {
          questionKey: 'hasMultipleDealerCodes',
          label: 'Multiple Dealer Codes',
          prompt: [
            {
              text: 'Do you have multiple dealer codes?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'canViewOtherInventory',
                'shareAssets',
                'howMoveCarsFinancially',
                'hasConsolidatedFinancialStatement',
                'multiOEMGL',
              ],
            },
          ],
        },
        canViewOtherInventory: {
          questionKey: 'canViewOtherInventory',
          label: 'View Other Inventory',
          prompt: [
            {
              text: "Do dealerships have the ability to view another dealership's inventory (parts or vehicles)?",
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        shareAssets: {
          questionKey: 'shareAssets',
          label: 'Share Assets',
          prompt: [
            {
              text: 'Do dealerships share customers, VINs, vendors, payroll, etc.?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        howMoveCarsFinancially: {
          questionKey: 'howMoveCarsFinancially',
          label: 'Move Cars Financially',
          prompt: [
            {
              text: 'How do dealers move cars financially between dealerships?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        hasConsolidatedFinancialStatement: {
          questionKey: 'hasConsolidatedFinancialStatement',
          label: 'Consolidated Financial Statement',
          prompt: [
            {
              text: 'Do you have a consolidated financial statement?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        multiOEMGL: {
          questionKey: 'multiOEMGL',
          label: 'Multi OEM GL',
          prompt: [
            {
              text: 'Do you separate your vehicle posting per OEM with separate GL accounts?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupOEMPayables: {
      groupLabel: 'OEM Payables',
      groupType: 'standard',
      groupUrl: 'groupOEMPayables',
      questions: {
        manualOEMPayableProcess: {
          questionKey: 'manualOEMPayableProcess',
          label: 'Manual OEM Payables',
          order: 1,
          prompt: [
            {
              text: 'When posting your OEM payables, are you doing a manual process?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        OEMPayableGL: {
          questionKey: 'OEMPayableGL',
          label: 'OEM Payable GL',
          order: 2,
          prompt: [
            {
              text: 'When posting OEM payables, what GL account are you using?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        OEMPayablesIsMainGL: {
          questionKey: 'OEMPayablesIsMainGL',
          label: 'OEM Payables Main GL',
          order: 3,
          prompt: [
            {
              text: 'Is this GL account your main accounts payable account?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
        OEMPayablesVendorControl: {
          questionKey: 'OEMPayablesVendorControl',
          label: 'OEM Payables Vendor Control',
          order: 4,
          prompt: [
            {
              text: 'What vendor/control are you using?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPositivePay: {
      groupLabel: 'Positive Pay',
      groupType: 'standard',
      groupUrl: 'groupPositivePay',
      questions: {
        usesPositivePay: {
          questionKey: 'usesPositivePay',
          label: 'Positive Pay',
          prompt: [
            {
              text: 'Do you plan to use positive pay?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'Check positive pay is a fraud mitigation service that provides early detection of fraudulent, altered, or counterfeit checks through daily verification of checks presented for payment against your check register.',
              attributes: null,
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [],
            },
          ],
        },
      },
    },
    groupAccountingCompliance: {
      groupLabel: 'Accounting Compliance',
      groupType: 'standard',
      groupUrl: 'groupAccountingCompliance',
      questions: {
        useRealTimeReceiptPosting: {
          questionKey: 'useRealTimeReceiptPosting',
          label: 'Use Real-Time Receipt Posting',
          prompt: [
            {
              attributes: null,
              text: 'Do you plan to use real-time receipt posting to be compliant with generally accepted accounting principles (GAAP)?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'We ask this now to prevent errors during the data conversion process.',
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'receiptClearingAccount',
                'receiptClearingJournal',
              ],
            },
          ],
        },
        receiptClearingAccount: {
          questionKey: 'receiptClearingAccount',
          label: 'Receipt Clearing Account',
          prompt: [
            {
              text: 'Which account will you use to clear receipts?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        receiptClearingJournal: {
          questionKey: 'receiptClearingJournal',
          label: 'Receipt Clearing Journal',
          prompt: [
            {
              text: 'Which journal will you use to clear receipts?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupUsedFloorPlan: {
      groupLabel: 'Used Floor Plan',
      groupUrl: 'groupUsedFloorPlan',
      groupType: 'standard',
      questions: {
        hasUsedFloorPlan: {
          questionKey: 'hasUsedFloorPlan',
          label: 'Used Floor Plan',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have a used floor plan?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['usedFloorPlanAPR', 'hasUsedFreeDays'],
            },
          ],
        },
        usedFloorPlanAPR: {
          questionKey: 'usedFloorPlanAPR',
          label: 'Used Floor Plan APR',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'What is your used floor plan APR?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        hasUsedFreeDays: {
          questionKey: 'hasUsedFreeDays',
          label: 'Used Floor Plan Free Days',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have used free days?',
            },
          ],
          subprompt: [],
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupMisc: {
      groupLabel: 'Miscellaneous',
      groupType: 'standard',
      groupUrl: 'groupMisc',
      questions: {
        timeOnRetiringDMS: {
          questionKey: 'timeOnRetiringDMS',
          label: 'Time on Retiring DMS',
          order: 1,
          prompt: [
            {
              text: 'How many years have you been using your retiring DMS?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'We ask this to better understand the amount of data that we will be migrating into VUE.',
              attributes: null,
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: '0-10 years',
              value: '<10',
            },
            {
              label: '11-20 years',
              value: '11-20',
            },
            {
              label: '21-30 years',
              value: '21-30',
            },
            {
              label: '30+ years',
              value: '>30',
            },
          ],
          validation: [],
          dependencies: [],
        },
        paysVendorsOnline: {
          questionKey: 'paysVendorsOnline',
          label: 'Pay Vendors Online',
          prompt: [
            {
              text: 'Do you pay any vendors online (EFT/ACH)?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'This includes any vendors that you do not write a check to but instead pay using your bank routing number and account number (possibly through an online payment portal).',
              attributes: null,
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['vendorNames'],
            },
          ],
        },
        vendorNames: {
          questionKey: 'vendorNames',
          label: 'Vendor Names',
          prompt: [
            {
              text: 'Please provide a list of ',
              attributes: null,
            },
            {
              attributes: { bold: true },
              text: 'all ',
            },
            {
              attributes: null,
              text: "vendors that you pay online, including each vendor's ",
            },
            {
              attributes: { bold: true },
              text: 'name ',
            },
            {
              text: 'and ',
              attributes: null,
            },
            {
              text: 'number.',
              attributes: { bold: true },
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        pcPrintCount: {
          questionKey: 'pcPrintCount',
          label: 'Number of Printing PCs',
          prompt: [
            {
              text: 'How many PCs in the accounting department will print from VUE?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This information will help us determine how many PCs require WebClient installation to be able to print from VUE.',
            },
          ],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        printers: {
          questionKey: 'printers',
          label: 'Printers',
          prompt: [
            {
              text: 'Please provide the type of printers (laser/impact) that you currently have in your accounting office that are being used by your retiring DMS.',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        employeeCustomerNumbers: {
          questionKey: 'employeeCustomerNumbers',
          label: 'Employee Numbers Match Customer Numbers',
          prompt: [
            {
              text: 'Do your employee numbers match their customer number in your retiring DMS?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: "Your response helps us to properly map your employees' customer activity into VUE.",
              attributes: null,
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['fileEmployeeCustNonMatchExample'],
            },
          ],
        },
        fileEmployeeCustNonMatchExample: {
          questionKey: 'fileEmployeeCustNonMatchExample',
          label: 'Employee/Customer Non-Match Example',
          prompt: [
            {
              text: 'Please provide some examples of employees who have a customer record that uses a customer number that is ',
              attributes: null,
            },
            {
              text: 'different ',
              attributes: { bold: true },
            },
            {
              text: 'from their employee number.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each example, please provide the employee number and the associated customer number.',
            },
          ],
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        electronicTitlingProcess: {
          questionKey: 'electronicTitlingProcess',
          label: 'Electronic Titling Process',
          prompt: [
            {
              text: 'Please explain your electronic titling process.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'Do you manually enter it? Is it just there when you enter the VIN? Do you have to pull the data in using the DMS deal number?',
              attributes: null,
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        useVUEPayroll: {
          questionKey: 'useVUEPayroll',
          label: 'Use VUE Payroll',
          prompt: [
            {
              text: "Do you plan to use VUE's Payroll?",
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['fileRTD'],
            },
          ],
        },
        fileRTD: {
          questionKey: 'fileRTD',
          label: 'Report Technician Detail File',
          prompt: [
            {
              text: 'Please provide a list containing all of your service or body shop technicians.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each technician, please provide:\n    \u2022 employee number\n    \u2022 employee name\n    \u2022 department\n    \u2022 role/position\n    \u2022 default hourly rate\n    \u2022 other pay rate',
            },
          ],
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },

        chartOfAccounts: {
          questionKey: 'chartOfAccounts',
          label: 'Chart of Accounts',
          prompt: [
            {
              text: 'Please provide a copy of your chart of accounts.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'It is best if you pull this information directly from your retiring DMS.\n\n We ask that you please:\n    \u2022 Provide this in Excel/XLSX format\n    \u2022 Provide as much detail as possible\n    \u2022 Include your OEM reporting accounts\n    \u2022 Include departmental split percentages for fixed\n       overhead expenses',
            },
          ],
          componentType: 'file',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupVendors: {
      groupLabel: 'Vendors',
      groupType: 'vendor',
      groupUrl: 'groupVendors',
      questions: {
        accountingElectronicTitlingVendor: {
          questionKey: 'accountingElectronicTitlingVendor',
          label: 'Electronic Titling Vendor',
          prompt: [
            {
              text: 'Please provide the name of your electronic titling vendor',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },

        accountingSignaturePadVendor: {
          questionKey: 'accountingSignaturePadVendor',
          label: 'Signature Pad Vendor',
          prompt: [
            {
              text: 'Please provide the name of your signature pad vendor',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        documentScanningVendor: {
          questionKey: 'documentScanningVendor',
          label: 'Document Scanning Vendor',
          prompt: [
            {
              text: 'Please provide the name of your document scanning vendor',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['accounting-preliminary'];
