import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import {
  IDiscoveryDataProperty,
  ResponseValidation,
} from '@dominion/interfaces';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { SharedModule } from '../../shared/shared.module';
import { ResponseHistoryTriggerComponent } from '../../response-history/response-history-trigger.component';
import { AutofocusDirective } from '../../shared/directives/autofocus.directive';

@Component({
  selector: 'dominion-input-textarea',
  standalone: true,
  imports: [
    CommonModule,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    DiscoveryNextBtnDirective,
    ReactiveFormsModule,
    SharedModule,
    ResponseHistoryTriggerComponent,
    AutofocusDirective,
  ],
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.css'],
})
export class InputTextareaComponent implements OnChanges, ResponseInput {
  @Input({ required: true }) data: IDiscoveryDataProperty<string>;
  @Input() validation?: ResponseValidation[] | undefined;
  @Input() isCurrentQuestion: boolean = false;

  @Output() response: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(PopoverHostDirective) popoverHost: PopoverHostDirective;

  form: FormGroup;
  errorMessage: string | undefined;
  serverErrMsg: string | undefined;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      text: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.setInitialValue();
    }
  }

  setInitialValue(): void {
    this.form.setValue({
      text: this.data.value,
    });
  }

  checkErrors() {
    if (this.form.invalid) {
      this.errorMessage = 'Please enter a valid response';
      this.popoverHost.show();
      return;
    }
    this.resetErrors();
  }

  save() {
    this.resetErrors();
    if (this.form.valid) {
      this.response.emit(this.form.value.text);
    } else {
      this.checkErrors();
    }
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.errorMessage = undefined;
    this.popoverHost.hide();
  }

  handleErr(err: string): void {
    throw new Error(err);
  }

  handleSuccess(): void {
    return;
  }
}
