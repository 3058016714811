import { Pipe, PipeTransform } from '@angular/core';
import { ResponseOptions } from '@dominion/interfaces';

type DateValue = {
  month: string;
  day: number;
};

type DropdownValue = {
  value: string;
  label: string;
};

type FileUploadValue = {
  dateUploaded: Date;
  name: string;
};

type MappingValue = {
  lhs: string;
  rhs: string;
};

/**
 * Format a question response (similar to the Question Data Pipe) when there is
 * no known component type.
 *
 * Accepts an array of options to reconcile values with a corresponding label.
 */
@Pipe({
  name: 'responseDataPipe',
  standalone: true,
})
export class ResponseDataPipe implements PipeTransform {
  transform(value: unknown, options?: ResponseOptions[]): string | null {
    if (value === undefined || value === null || value === '') {
      return null;
    }

    if (options?.length) {
      const normalizedValue = this.getNormalizedValue(value);
      const label = options.find((option) => option.value === normalizedValue)
        ?.label;

      if (label) {
        return label;
      }
    }

    if (typeof value === 'string') {
      return value;
    }

    if (Array.isArray(value)) {
      return this.formatArray(value, options);
    }

    if (typeof value === 'object') {
      if (this.isDateValue(value)) {
        return this.formatDateValue(value);
      }

      if (this.isDropdownValue(value)) {
        return value.label;
      }

      if (this.isMappingValue(value)) {
        return this.formatMappingValue(value, options);
      }
    }

    return null;
  }

  private formatArray(
    arr: unknown[],
    options?: ResponseOptions[],
  ): string | null {
    const formattedItems = arr
      .map((item) => {
        if (typeof item === 'string') {
          return item;
        }

        if (this.isDropdownValue(item)) {
          return item.label;
        }

        if (this.isFileUploadValue(item)) {
          return item.name;
        }

        if (this.isMappingValue(item)) {
          return this.formatMappingValue(item, options);
        }

        return null;
      })
      .filter((v) => v);

    return formattedItems.length ? formattedItems.join(', ') : null;
  }

  private formatDateValue(value: DateValue): string {
    return `${value.month.charAt(0).toUpperCase()}${value.month.slice(1)} ${
      value.day
    }`;
  }

  private formatMappingValue(
    value: MappingValue,
    options?: ResponseOptions[],
  ): string {
    const rhsLabel =
      options?.find((option) => option.value === value.rhs)?.label ?? value.rhs;
    return `${value.lhs} → ${rhsLabel}`;
  }

  private getNormalizedValue(value: unknown): unknown {
    return value && typeof value === 'object' && 'value' in value
      ? (value as { value: unknown }).value
      : value;
  }

  private isDateValue(value: unknown): value is DateValue {
    return (
      this.hasProperties(value, ['month', 'day']) &&
      typeof (value as DateValue).month === 'string' &&
      typeof (value as DateValue).day === 'number'
    );
  }

  private isDropdownValue(value: unknown): value is DropdownValue {
    return (
      this.hasProperties(value, ['value', 'label']) &&
      typeof (value as DropdownValue).value === 'string' &&
      typeof (value as DropdownValue).label === 'string'
    );
  }

  private isFileUploadValue(value: unknown): value is FileUploadValue {
    return (
      this.hasProperties(value, ['dateUploaded', 'name']) &&
      typeof (value as FileUploadValue).name === 'string' &&
      Boolean((value as FileUploadValue).name)
    );
  }

  private isMappingValue(value: unknown): value is MappingValue {
    return this.hasProperties(value, ['lhs', 'rhs']);
  }

  private hasProperties(value: unknown, props: string[]): boolean {
    return (
      value !== null &&
      typeof value === 'object' &&
      props.every((prop) => prop in value)
    );
  }
}
