import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFromEmail',
  standalone: true,
})
export class NameFromEmailPipe implements PipeTransform {
  transform(email: string): string {
    const [name, domain] = email.split('@');

    if (!domain) {
      return name;
    }

    // Replace common first and last name delimiters with a space
    return name.replace(/[._\-+]/g, ' ');
  }
}
