import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-delete',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ strokeWidth }} {{ fillColor }}"
  >
    <defs>
      <style>
        .i-delete {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title>Delete</title>
    <line class="i-delete" x1="1.5" y1="4.5" x2="22.5" y2="4.5" />
    <path
      class="i-delete"
      d="M14.25,1.5H9.75A1.5,1.5,0,0,0,8.25,3V4.5h7.5V3A1.5,1.5,0,0,0,14.25,1.5Z"
    />
    <line class="i-delete" x1="9.75" y1="17.25" x2="9.75" y2="9.75" />
    <line class="i-delete" x1="14.25" y1="17.25" x2="14.25" y2="9.75" />
    <path
      class="i-delete"
      d="M18.865,21.124A1.5,1.5,0,0,1,17.37,22.5H6.631a1.5,1.5,0,0,1-1.495-1.376L3.75,4.5h16.5Z"
    />
  </svg>`,
})
export class IconDeleteComponent extends IconBase {}
