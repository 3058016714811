export const DYNAMIC_OPTIONS_SOURCES = {
  securevue: {
    identifiers: [
      'glmaster',
      'journals',
      'schedules',
      'lenders',
      'parts-account-codes',
      'service-account-codes',
      'parts-price-codes',
    ] as const,
  },
} as const;

export type DynamicOptionsSources = keyof typeof DYNAMIC_OPTIONS_SOURCES;

export type DynamicOptionsIdentifiers<T extends DynamicOptionsSources> =
  (typeof DYNAMIC_OPTIONS_SOURCES)[T]['identifiers'][number];

export function isOptionsSource(
  source: string,
): source is DynamicOptionsSources {
  return Object.keys(DYNAMIC_OPTIONS_SOURCES).includes(source);
}

export function isOptionsIdentifier<T extends DynamicOptionsSources>(
  source: T,
  identifier: string,
): identifier is DynamicOptionsIdentifiers<T> {
  return DYNAMIC_OPTIONS_SOURCES[source].identifiers.includes(
    identifier as any,
  );
}
