import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/module/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/submodule/core-submodule.interfaces';

export type TSalesMaintenanceGroupIdentifiers =
  | 'groupCashDeals'
  | 'groupLaserForms'
  | 'groupLeaseDeals'
  | 'groupRetailDeals'
  | 'groupCommission'
  | 'groupSalesInsurances'
  | 'groupSalesLenders'
  | 'groupSalesServiceContracts'
  | 'groupVehicleInventory'
  | 'groupSalesFees';

export type TSalesMaintenanceDataKeys =
  | 'cashDeals'
  | 'laserForms'
  | 'leaseDeals'
  | 'retailDeals'
  | 'salesInsurances'
  | 'salesLenders'
  | 'salesServiceContracts'
  | 'hasWrittenCommsPlan'
  | 'writtenCommsPlan'
  | 'baseCommission'
  | 'hasFlatMiniDeal'
  | 'flatMiniDealAmount'
  | 'isTierPlan'
  | 'tierDetails'
  | 'isPaidOnHoldback'
  | 'isPaidOnFinanceReserve'
  | 'financeReserveAmount'
  | 'isPaidOnServiceContract'
  | 'serviceContractAmount'
  | 'isPaidOnInsurance'
  | 'insuranceAmount'
  | 'variableSpiffs'
  | 'vehicleInventory'
  | 'salesFees';

const salesMaintenanceGroupMap = {
  moduleType: 'sales',
  submoduleType: 'maintenance',
  discriminator: 'sales-maintenance',
  groups: {
    groupCommission: [
      'hasWrittenCommsPlan',
      'writtenCommsPlan',
      'baseCommission',
      'hasFlatMiniDeal',
      'flatMiniDealAmount',
      'isTierPlan',
      'tierDetails',
      'isPaidOnHoldback',
      'isPaidOnFinanceReserve',
      'financeReserveAmount',
      'isPaidOnServiceContract',
      'serviceContractAmount',
      'isPaidOnInsurance',
      'insuranceAmount',
      'variableSpiffs',
    ],
    groupCashDeals: ['cashDeals'],
    groupLaserForms: ['laserForms'],
    groupLeaseDeals: ['leaseDeals'],
    groupRetailDeals: ['retailDeals'],
    groupVehicleInventory: ['vehicleInventory'],
    groupSalesInsurances: ['salesInsurances'],
    groupSalesLenders: ['salesLenders'],
    groupSalesServiceContracts: ['salesServiceContracts'],
    groupSalesFees: ['salesFees'],
  },
} satisfies TSubmoduleGroupMap<
  TSalesMaintenanceGroupIdentifiers,
  TSalesMaintenanceDataKeys
>;

export type TSalesMaintenanceGroupMap = typeof salesMaintenanceGroupMap;

export class SalesMaintenanceData
  implements TDataDefinition<TSalesMaintenanceDataKeys>
{
  cashDeals = new DataInitializerBasic<IFileUploadReference[]>([]);
  laserForms = new DataInitializerBasic<IFileUploadReference[]>([]);
  leaseDeals = new DataInitializerBasic<IFileUploadReference[]>([]);
  retailDeals = new DataInitializerBasic<IFileUploadReference[]>([]);
  vehicleInventory = new DataInitializerBasic<IFileUploadReference[]>([]);
  hasWrittenCommsPlan = new DataInitializerBasic<boolean>();
  writtenCommsPlan = new DataInitializerBasic<IFileUploadReference[]>([]);
  baseCommission = new DataInitializerBasic<string>();
  hasFlatMiniDeal = new DataInitializerBasic<boolean>();
  flatMiniDealAmount = new DataInitializerBasic<string>();
  isTierPlan = new DataInitializerBasic<boolean>();
  tierDetails = new DataInitializerBasic<string>();
  isPaidOnHoldback = new DataInitializerBasic<boolean>();
  isPaidOnFinanceReserve = new DataInitializerBasic<boolean>();
  financeReserveAmount = new DataInitializerBasic<string>();
  isPaidOnServiceContract = new DataInitializerBasic<boolean>();
  serviceContractAmount = new DataInitializerBasic<string>();
  isPaidOnInsurance = new DataInitializerBasic<boolean>();
  insuranceAmount = new DataInitializerBasic<string>();
  variableSpiffs = new DataInitializerBasic<string>();
  salesInsurances = new DataInitializerBasic<IFileUploadReference[]>([]);
  salesLenders = new DataInitializerBasic<IFileUploadReference[]>([]);
  salesServiceContracts = new DataInitializerBasic<IFileUploadReference[]>([]);
  salesFees = new DataInitializerBasic<IFileUploadReference[]>([]);
}

export const salesMaintenanceStructure: Structures['sales-maintenance'] = {
  moduleType: 'sales',
  submoduleType: 'maintenance',
  discriminator: 'sales-maintenance',
  groups: {
    groupSalesFees: {
      groupLabel: 'Sales Fees',
      groupUrl: 'groupSalesFees',
      groupType: 'standard',
      questions: {
        salesFees: {
          questionKey: 'salesFees',
          label: 'Sales Fees',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your most common sales fees.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'During the setup call, we will review the details for each fee.\n\nFor each fee, please provide:\n    \u2022 the code\n    \u2022 the description',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupCommission: {
      groupLabel: 'Commission',
      groupUrl: 'groupCommission',
      groupType: 'standard',
      questions: {
        hasWrittenCommsPlan: {
          questionKey: 'hasWrittenCommsPlan',
          label: 'Has Written Commission Plan',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have a written commission pay plan?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['writtenCommsPlan'],
            },
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: [
                'baseCommission',
                'hasFlatMiniDeal',
                'isTierPlan',
                'isPaidOnHoldback',
                'isPaidOnFinanceReserve',
                'isPaidOnServiceContract',
                'isPaidOnInsurance',
                'variableSpiffs',
              ],
            },
          ],
        },
        writtenCommsPlan: {
          questionKey: 'writtenCommsPlan',
          label: 'Written Commission Plan',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Please upload your written commission pay plan.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        baseCommission: {
          questionKey: 'baseCommission',
          label: 'Base Commission',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'What is the base commission percentage paid to salespeople?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        hasFlatMiniDeal: {
          questionKey: 'hasFlatMiniDeal',
          label: 'Has Flat Mini Deal',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you pay a flat amount for mini deals?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['flatMiniDealAmount'],
            },
          ],
        },
        flatMiniDealAmount: {
          questionKey: 'flatMiniDealAmount',
          label: 'Flat Mini Deal Amount',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'What is the flat amount paid for mini deals?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        isTierPlan: {
          questionKey: 'isTierPlan',
          label: 'Is Tier Plan',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Is your pay plan a tiered plan?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['tierDetails'],
            },
          ],
        },
        tierDetails: {
          questionKey: 'tierDetails',
          label: 'Tier Details',
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Please list your tier levels and amounts.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        isPaidOnHoldback: {
          questionKey: 'isPaidOnHoldback',
          label: 'Is Paid on Holdback',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you pay commission on the holdback?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        isPaidOnFinanceReserve: {
          questionKey: 'isPaidOnFinanceReserve',
          label: 'Is Paid on Finance Reserve',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you pay commission on finance reserve income?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['financeReserveAmount'],
            },
          ],
        },
        financeReserveAmount: {
          questionKey: 'financeReserveAmount',
          label: 'Finance Reserve Amount',
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Please describe the flat amount or percentage paid on finance reserve income and any caps on amounts paid.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        isPaidOnServiceContract: {
          questionKey: 'isPaidOnServiceContract',
          label: 'Is Paid on Service Contract',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you pay commission on service contract income?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['serviceContractAmount'],
            },
          ],
        },
        serviceContractAmount: {
          questionKey: 'serviceContractAmount',
          label: 'Service Contract Amount',
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Please describe the flat amount or percentage paid on service contract income and any caps on amounts paid.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        isPaidOnInsurance: {
          questionKey: 'isPaidOnInsurance',
          label: 'Is Paid on Insurance',
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you pay commission on insurance income?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['insuranceAmount'],
            },
          ],
        },
        insuranceAmount: {
          questionKey: 'insuranceAmount',
          label: 'Insurance Amount',
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Please describe the flat amount or percentage paid on insurance income and any caps on amounts paid.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        variableSpiffs: {
          questionKey: 'variableSpiffs',
          label: 'Variable Spiffs',
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Please describe any variable spiffs or bonuses paid to salespeople.',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSalesInsurances: {
      groupLabel: 'Insurances',
      groupUrl: 'groupSalesInsurances',
      groupType: 'standard',
      questions: {
        salesInsurances: {
          questionKey: 'salesInsurances',
          label: 'Sales Insurances',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of insurances, ',
            },
            {
              attributes: { bold: true },
              text: 'including GAP',
            },
            {
              attributes: null,
              text: ', that you want to be added to VUE.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each insurance, please provide:\n    \u2022 the insurance name\n    \u2022 address\n    \u2022 phone number',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSalesLenders: {
      groupLabel: 'Lenders',
      groupUrl: 'groupSalesLenders',
      groupType: 'standard',
      questions: {
        salesLenders: {
          questionKey: 'salesLenders',
          label: 'Sales Lenders',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your most used retail and lease lenders.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'VUE only allows lender codes with alphabetic characters. Any needed updates can be addressed during conversion.\n\nFor each lender, please provide:\n    \u2022 the lender code\n    \u2022 the lender name\n    \u2022 contact person\n    \u2022 address\n    \u2022 phone number\n    \u2022 fax number',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSalesServiceContracts: {
      groupLabel: 'Service Contracts',
      groupUrl: 'groupSalesServiceContracts',
      groupType: 'standard',
      questions: {
        salesServiceContracts: {
          questionKey: 'salesServiceContracts',
          label: 'Sales Service Contracts',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of the service contracts that you would like to be added to VUE.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each service contract, please provide:\n    \u2022 the vendor name\n    \u2022 address\n    \u2022 phone number',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupCashDeals: {
      groupLabel: 'Cash Deals',
      groupUrl: 'groupCashDeals',
      groupType: 'standard',
      questions: {
        cashDeals: {
          questionKey: 'cashDeals',
          label: 'Cash Deals',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide ',
            },
            {
              attributes: { bold: true },
              text: '3 cash deals ',
            },
            {
              attributes: null,
              text: 'posted in accounting and funded with recaps.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Please include all sales and F&I forms. We use these to: (1) program your forms in VUE; (2) verify that VUE is properly generating your forms. If we discover during go-live prep that there are more forms to program, this could delay your go-live date.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },

    groupLeaseDeals: {
      groupLabel: 'Lease Deals',
      groupUrl: 'groupLeaseDeals',
      groupType: 'standard',
      questions: {
        leaseDeals: {
          questionKey: 'leaseDeals',
          label: 'Lease Deals',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide ',
            },
            {
              attributes: { bold: true },
              text: '3 lease deals ',
            },
            {
              attributes: null,
              text: 'posted in accounting and funded with recaps.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Please include all sales and F&I forms. We use these to (1) program your forms in VUE, and (2) verify that VUE is properly generating your forms. If we discover during go-live prep that there are more forms to program, this could delay your go-live date.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupRetailDeals: {
      groupLabel: 'Retail Deals',
      groupUrl: 'groupRetailDeals',
      groupType: 'standard',
      questions: {
        retailDeals: {
          questionKey: 'retailDeals',
          label: 'Retail Deals',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide ',
            },
            {
              attributes: { bold: true },
              text: '3 retail deals ',
            },
            {
              attributes: null,
              text: 'posted in accounting and funded with recaps.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Please include all sales and F&I forms. We use these to (1) program your forms in VUE, and (2) verify that VUE is properly generating your forms. If we discover during go-live prep that there are more forms to program, this could delay your go-live date.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupLaserForms: {
      groupLabel: 'Laser Forms',
      groupUrl: 'groupLaserForms',
      groupType: 'standard',
      questions: {
        laserForms: {
          questionKey: 'laserForms',
          label: 'Laser Forms',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide blank PDFs of ALL laser forms.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'These must be ',
            },
            {
              attributes: { bold: true },
              text: 'blank ',
            },
            {
              attributes: null,
              text: 'for successful programming.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupVehicleInventory: {
      groupLabel: 'Vehicle Inventory',
      groupUrl: 'groupVehicleInventory',
      groupType: 'standard',
      questions: {
        vehicleInventory: {
          questionKey: 'vehicleInventory',
          label: 'Vehicle Inventory',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a report from your retiring DMS containing your new and used car inventory.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Please separate your new and used car inventory, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['sales-maintenance'];
