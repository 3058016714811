export type QuestionComponentType =
  | 'text'
  | 'textarea'
  | 'select'
  | 'select-list'
  | 'select-multi'
  | 'dropdown'
  | 'dropdown-multi'
  | 'date'
  | 'time'
  | 'month-day'
  | 'address'
  | 'file'
  | 'file-multi'
  | 'contact'
  | 'mapping'
  | 'vendor';
