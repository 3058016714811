import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DataInitializerBasic,
  DataInitializerOther,
  Structures,
  ISubmoduleResponse,
  TGenericGroup,
  TGenericQStruct,
  TSubmoduleDiscriminators,
  isKeyOf,
} from '@dominion/interfaces';
import { PromptComponent } from '../prompt/prompt.component';
import { CompanyService } from '../company/company.service';
import { InputTextComponent } from '../inputs/input-text/input-text.component';
import { InputTextareaComponent } from '../inputs/input-textarea/input-textarea.component';
import { InputSelectComponent } from '../inputs/input-select/input-select.component';
import { InputSelectListComponent } from '../inputs/input-select-list/input-select-list.component';
import { InputDropdownComponent } from '../inputs/input-dropdown/input-dropdown.component';
import { InputFileComponent } from '../inputs/input-file/input-file.component';
import { InputTimeComponent } from '../inputs/input-time/input-time.component';
import { InputMonthDayComponent } from '../inputs/input-month-day/input-month-day.component';
import { InputDateComponent } from '../inputs/input-date/input-date.component';
import { InputAddressComponent } from '../inputs/input-address/input-address.component';
import { InputContactComponent } from '../inputs/input-contact/input-contact.component';
import { InputMappingComponent } from '../inputs/input-mapping/input-mapping.component';
import { ResponseInput } from '../inputs/input.interface';
import { firstQuestionAnimation } from '../shared/animations/first-question.animation';
import { ActivatedRoute, Router } from '@angular/router';
import { InputFileContainerComponent } from '../inputs/input-file-container/input-file-container.component';
import { GroupPipe } from '../shared/pipes/group.pipe';

@Component({
  selector: 'dominion-standard-group',
  standalone: true,
  imports: [
    CommonModule,
    PromptComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputSelectComponent,
    InputSelectListComponent,
    InputDropdownComponent,
    InputTimeComponent,
    InputMonthDayComponent,
    InputDateComponent,
    InputFileContainerComponent,
    InputAddressComponent,
    InputContactComponent,
    InputMappingComponent,
  ],
  templateUrl: './standard-group.component.html',
  styleUrls: ['./standard-group.component.css'],
  animations: [firstQuestionAnimation],
})
export class StandardGroupComponent implements OnInit {
  @Input({ required: true }) companyid: string;
  @Input({ required: true }) moduleid: string;
  @Input({ required: true }) submoduleid: string;
  @Input({ required: true }) group: TGenericGroup<string>;
  @Input({ required: true }) structure: Structures[TSubmoduleDiscriminators];
  @Input({ required: true }) data: {
    [key in keyof typeof this.group.questions]:
      | DataInitializerBasic<any>
      | DataInitializerOther<any>;
  };

  @ViewChild('container') container: ElementRef<HTMLDivElement>;

  activeIndex = 0;
  requiredQuestions: TGenericQStruct<string>[] = [];
  orderedGroups: TGenericGroup<string>[] = [];

  constructor(
    private companyService: CompanyService,
    private el: ElementRef<HTMLDivElement>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.initializeQuestions();
  }

  // build the initial question list, adding required Qs
  private initializeQuestions() {
    if (!this.group) {
      return;
    }
    this.requiredQuestions = [];
    this.group.questions;
    for (const question in this.group.questions) {
      if (isKeyOf(this.group.questions, question)) {
        if (this.group.questions[question].requirement === 'required') {
          this.requiredQuestions.push(this.group.questions[question]);
        }
      }
    }
    this.initializeGroupArray();
  }

  initializeGroupArray() {
    const pipe = new GroupPipe();
    this.orderedGroups = pipe.transform(this.structure.groups);
  }

  // when a response event is detected, this function will trigger the call to the server to save the response
  recordResponse(
    data: any,
    questionKey: string,
    inputComponent: ResponseInput,
    index: number,
  ) {
    if (this.companyid && this.moduleid && this.submoduleid) {
      const dto: ISubmoduleResponse = {
        submoduleId: this.submoduleid,
        response: {
          field: questionKey,
          value: data,
        },
      };
      this.companyService.saveSubmoduleResponse(dto).subscribe({
        next: (res) => {
          inputComponent.handleSuccess(res);
          this.data[questionKey].value = data;
          this.analyzeRequiredQuestions(questionKey, index);
        },
        error: (err) => {
          inputComponent.handleErr(err);
        },
      });
    }
  }

  recordPresavedResponse(data: any, questionKey: string, index: number) {
    this.data[questionKey].value = data;
  }

  advance(questionKey: string, index: number) {
    this.analyzeRequiredQuestions(questionKey, index);
  }

  // after a response is recorded, we call this function to determine if any changes need to be made to the requiredQuestions list based on new dependencies
  private analyzeRequiredQuestions(
    questionKey: keyof typeof this.group.questions,
    index: number,
  ) {
    const question = this.group.questions[questionKey];
    if (
      index < this.activeIndex &&
      this.group.questions[questionKey].dependencies.length > 0
    ) {
      // this means they answered a question before the active question
      this.removeDependentQuestions(questionKey);
      this.activeIndex = index;
    }
    if (question.dependencies.length > 0) {
      for (const dependency of question.dependencies) {
        if (
          this.isDependencySatisfied(dependency, this.data[questionKey].value)
        ) {
          this.addDependentQuestions(dependency.dependentKeys);
        }
      }
    }
    if (index === this.activeIndex) {
      this.activeIndex++;
    }
    // if we completed the last question, move to the next group or return to the dashboard
    if (this.activeIndex > this.requiredQuestions.length - 1) {
      // move to next group if there is another one
      const groupIndex = this.orderedGroups.findIndex(
        (group) => group.groupUrl === this.group.groupUrl,
      );
      if (groupIndex < this.orderedGroups.length - 1) {
        this.router.navigate(
          ['../', this.orderedGroups[groupIndex + 1].groupUrl],
          {
            relativeTo: this.route,
          },
        );
      } else {
        // go back to dashboard if last group
        this.router.navigate(['../../../'], {
          relativeTo: this.route,
        });
      }
    }
    setTimeout(() => {
      this.container.nativeElement.lastElementChild?.scrollIntoView();
    });
  }

  private removeDependentQuestions(questionKey: string) {
    for (const dependency of this.group.questions[questionKey].dependencies) {
      for (const key of dependency.dependentKeys) {
        const index = this.requiredQuestions.findIndex(
          (question) => question.questionKey === key,
        );
        if (index > -1) {
          this.requiredQuestions.splice(index, 1);
        }
        this.removeDependentQuestions(key);
      }
    }
  }

  private isDependencySatisfied(
    dependency: TGenericQStruct<string>['dependencies'][0],
    value: any,
  ) {
    switch (dependency.comparisonMethod) {
      case 'equals':
        return value === dependency.comparisonValue;
      case 'greater-than':
        return value > dependency.comparisonValue;
      case 'less-than':
        return value < dependency.comparisonValue;
      case 'greater-than-or-equal-to':
        return value >= dependency.comparisonValue;
      case 'less-than-or-equal-to':
        return value <= dependency.comparisonValue;
      case 'not-equal-to':
        return value !== dependency.comparisonValue;
      default:
        return false;
    }
  }

  addDependentQuestions(keys: string[]) {
    const questions = [];
    for (const key of keys) {
      const question = this.group.questions[key];
      if (question) {
        questions.push(question);
      }
    }
    this.requiredQuestions.splice(this.activeIndex + 1, 0, ...questions);
  }
}
