<div [id]="module ? module.moduleType : 'module'" class="flex-1 p-4">
  <div class="flex max-w-4xl flex-wrap items-center justify-between">
    <h2 class="text-dms-teal text-xl font-bold">
      @if (module) {
        {{ module.moduleType | moduleType }}
      }
    </h2>
    @if (assignedUser && assignedUser.userType === 'external') {
      <a
        [routerLink]="getAssignedUserPath()"
        class="flex items-center whitespace-nowrap rounded-md border bg-gray-50 px-3 py-1.5 text-sm text-gray-700 shadow-sm transition hover:bg-gray-100"
      >
        <span>Assigned to</span>
        <div
          class="bg-dms-light-green ml-2 mr-1 flex h-3.5 w-3.5 items-center justify-center rounded-sm text-xs font-medium uppercase text-white"
        >
          @if (assignedUser.firstName) {
            {{ assignedUser.firstName.substring(0, 1) }}
          } @else {
            {{ assignedUser.email.substring(0, 1) }}
          }
        </div>
        <p class="text-dms-dark-green font-medium">
          @if (assignedUser.firstName) {
            {{ assignedUser.firstName }}
            @if (assignedUser.lastName) {
              {{ assignedUser.lastName }}
            }
          } @else {
            {{ assignedUser.email }}
          }
        </p>
      </a>
    } @else {
      <div
        class="flex items-center whitespace-nowrap rounded-md border bg-gray-50 px-3 py-1.5 text-sm text-gray-700"
      >
        <span>Unassigned</span>
      </div>
    }
  </div>

  <div class="h-6"></div>

  @if (module && submodules.length > 0) {
    <div class="flex max-w-4xl flex-col gap-6">
      @for (
        submodule of submodules;
        track submodule ? submodule._id : submoduleIndex;
        let submoduleIndex = $index
      ) {
        @if (submodule) {
          @if (
            user?.userType === 'internal' ||
            module.submodules[submoduleIndex].isAvailable
          ) {
            <dominion-core-card
              [id]="submodule.submoduleType"
              [title]="
                (submodule.discriminator | submodulePhase) +
                ' (' +
                submodule.submoduleType +
                ')'
              "
              [titleDetail]="
                module.submodules[submoduleIndex].dueDate
                  ? 'Due ' +
                    (module.submodules[submoduleIndex].dueDate
                      | date: 'MM/dd/yyyy')
                  : undefined
              "
              [hasPadding]="false"
            >
              <menu class="ml-3 flex items-center gap-3">
                <li>
                  <button
                    (click)="openSubmoduleSummary(submoduleIndex)"
                    class="flex cursor-pointer items-center justify-center gap-1.5 rounded-sm border border-gray-600/10 bg-gray-50 py-0.5 pl-1.5 pr-2 text-sm text-gray-600 transition hover:border-gray-600/20 hover:bg-gray-100 hover:text-gray-800"
                  >
                    <dominion-icon-clipboard-list
                      size="14"
                    ></dominion-icon-clipboard-list>
                    <span>Summary</span>
                  </button>
                </li>
                @if (user?.userType === 'internal') {
                  <li>
                    <button
                      (click)="getSubmoduleExport(submodule._id)"
                      class="flex cursor-pointer items-center justify-center gap-1.5 rounded-sm border border-gray-600/10 bg-gray-50 py-0.5 pl-1.5 pr-2 text-sm text-gray-600 transition hover:border-gray-600/20 hover:bg-gray-100 hover:text-gray-800"
                    >
                      <dominion-icon-export size="14"></dominion-icon-export>
                      <span>Export</span>
                    </button>
                  </li>
                }
              </menu>
              <div class="flex w-full flex-col">
                <dominion-table
                  id="phase-{{ submoduleIndex }}-groups-table"
                  [compact]="true"
                  [hideFrame]="true"
                >
                  <thead>
                    <tr>
                      <th scope="col">Question Group</th>
                      <th scope="col">Progress</th>
                      <th scope="col">
                        <span class="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (
                      group of maps[submodule.discriminator]['groups']
                        | groupPipe;
                      track group.groupUrl
                    ) {
                      <tr class="[&>td]:last:border-none">
                        <td>
                          <span
                            class="{{
                              getGroupCompletionStatus(
                                submodule,
                                group.groupUrl
                              ) === 100
                                ? 'text-gray-500 font-normal'
                                : 'font-semibold'
                            }}"
                          >
                            {{ group.groupLabel }}
                          </span>
                        </td>
                        <td>
                          @if (
                            getGroupCompletionStatus(
                              submodule,
                              group.groupUrl
                            ) === 100
                          ) {
                            <span class="text-gray-500">Complete</span>
                          } @else if (
                            getGroupCompletionStatus(
                              submodule,
                              group.groupUrl
                            ) === 0
                          ) {
                            <span class="text-gray-400">&mdash;</span>
                          } @else {
                            <dominion-completion-status-bar
                              class="h-2.5 max-w-[100px]"
                              [completionColor]="'bg-[#9ecf63]'"
                              [bgColor]="'bg-gray-200'"
                              [rounded]="'rounded-sm'"
                              [percentage]="
                                getGroupCompletionStatus(
                                  submodule,
                                  group.groupUrl
                                )
                              "
                            ></dominion-completion-status-bar>
                          }
                        </td>
                        <td align="right">
                          <div
                            class="flex max-w-full items-center justify-end gap-6"
                          >
                            <div class="w-28">
                              @switch (
                                getGroupActionLabel(submodule, group.groupUrl)
                              ) {
                                @case ('Continue') {
                                  <a
                                    [routerLink]="
                                      getGroupLink(submodule, group.groupUrl)
                                    "
                                    class="bg-dms-teal hover:bg-dms-teal/90 border-dms-teal flex items-center justify-center gap-2 rounded-md border px-3 py-1 text-sm font-medium text-white transition"
                                    >Continue</a
                                  >
                                }
                                @case ('Revisit') {
                                  <a
                                    [routerLink]="
                                      getGroupLink(submodule, group.groupUrl)
                                    "
                                    class="flex items-center justify-center gap-2 rounded-md border border-gray-600/10 bg-gray-100 px-3 py-1 text-sm font-medium text-gray-600 transition hover:bg-gray-200"
                                    >Revisit</a
                                  >
                                }
                                @default {
                                  <a
                                    [routerLink]="
                                      getGroupLink(submodule, group.groupUrl)
                                    "
                                    class="bg-dms-teal hover:bg-dms-teal/90 border-dms-teal flex items-center justify-center gap-2 rounded-md border px-3 py-1 text-sm font-medium text-white transition"
                                  >
                                    Start
                                  </a>
                                }
                              }
                            </div>
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </dominion-table>
              </div>
            </dominion-core-card>
          } @else {
            <dominion-core-card
              [id]="submodule.submoduleType"
              [title]="
                'Phase ' +
                (submoduleIndex + 1) +
                ' (' +
                submodule.submoduleType +
                ')'
              "
              [titleDetail]="
                module.submodules[submoduleIndex].dueDate
                  ? 'Due ' +
                    (module.submodules[submoduleIndex].dueDate
                      | date: 'MM/dd/yyyy')
                  : undefined
              "
              class="pointer-events-none cursor-not-allowed opacity-60"
            >
              <div class="flex w-full items-center gap-2 px-4 py-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-4 w-4 text-gray-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                <span class="text-sm text-gray-600">Locked</span>
              </div>
            </dominion-core-card>
          }
        }
      }
    </div>
  }

  <div class="h-10"></div>
</div>

<dominion-discovery-summary
  #summary
  [summaryData]="summaryData"
  [isInternalUser]="user?.userType === 'internal'"
></dominion-discovery-summary>
