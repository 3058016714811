import { IUserAuthorizations, IUserResourceAuth } from '@dominion/interfaces';
import { AuthorizationStrategy, IAuthorization } from '@dominion/interfaces';

export interface ResourceAuthQuery {
  resource: string;
  roles: string[];
  parents: {
    resource: string;
    roles: string[];
  }[];
}

/*
  AUTHORIZATION SERVICE
*/

export class Authorization {
  constructor() {}

  private checkAuthorization(
    resourceAuth: IUserResourceAuth,
    authorization: IAuthorization,
  ): boolean {
    // check roles
    for (const role of resourceAuth.roles) {
      if (authorization.roles.includes(role)) {
        return true;
      }
      continue;
    }
    // check permissions
    for (const permission of resourceAuth.permissions) {
      if (authorization.permissions.includes(permission)) {
        return true;
      }
      continue;
    }
    return false;
  }

  public isAuthorized(
    user: IUserAuthorizations,
    strategies: AuthorizationStrategy,
  ): boolean {
    if (!user || !strategies || strategies.length === 0) {
      return false;
    }
    // first loop iterates of each resource strategy
    for (const strategy of strategies) {
      let cumulativeKey = '';
      // second loop iterates over each authorization in the stretegy
      // this is each level of the resource hierarchy
      for (const authorization of strategy.authorizations) {
        // we construct the key and check for it on the user
        // if the user has the key we check roles and permissions
        // if not, then we continue the loop
        const key = `${authorization.type}:${authorization.id}`;
        // we want to add this key to the prefix for the next level of the hierarchy
        // if the prefix is empty, just set to the key
        // if the prefix is not empty, then add a colon and the key
        cumulativeKey = cumulativeKey ? `${cumulativeKey}:${key}` : key;
        // if the user has the key, then we check roles and permissions
        // if they do not have the key, then we skip to the next turn of the loop
        if (user[cumulativeKey]) {
          if (this.checkAuthorization(user[cumulativeKey], authorization)) {
            return true;
          }
        }
        continue;
      }
      continue;
    }
    return false;
  }
}
