<dialog
  #dialog
  aria-labelledby="summary-title"
  [attr.aria-hidden]="!dialogOpen"
  class="fixed inset-0 antialiased backdrop:bg-black/50 backdrop:transition-opacity backdrop:duration-200 backdrop:ease-in-out"
>
  <div class="fixed inset-y-0 right-0">
    @if (summaryData && dialogOpen) {
      <aside
        [@slideOver]
        class="flex h-full w-[576px] flex-col overflow-hidden bg-white shadow-2xl"
      >
        <header
          class="text-dms-teal z-20 flex flex-col gap-6 border-b px-4 pt-3"
        >
          <div class="flex items-center justify-between">
            <h2 id="summary-title" class="truncate font-semibold">
              @if (summaryData.submodulePhase) {
                {{ summaryData.submodulePhase | submodulePhase }}
              } @else {
                Summary
              }

              @if (summaryData.moduleType) {
                &mdash; {{ summaryData.moduleType | moduleType }}
              }
            </h2>

            <button
              type="button"
              (click)="close()"
              aria-label="Close summary panel"
              class="hover:ring-dms-light-green flex size-7 shrink-0 items-center justify-center rounded-sm text-gray-500 hover:text-gray-700 hover:ring-2 focus:outline-none focus:ring-2"
            >
              <span class="sr-only">Close</span>
              <dominion-icon-close
                width="w-3.5"
                height="h-3.5"
              ></dominion-icon-close>
            </button>
          </div>

          <label
            class="flex w-full items-center rounded-sm border bg-white pl-2 text-sm transition focus-within:border"
          >
            <dominion-icon-search
              width="w-4"
              height="h-4"
            ></dominion-icon-search>
            <input
              type="text"
              placeholder="Search questions..."
              [ngModel]="searchQuery"
              (ngModelChange)="setSearchQuery($event)"
              class="h-7 w-full bg-transparent px-2 placeholder:text-sm focus:outline-none"
            />
            @if (searchQuery.length > 0) {
              <button
                (click)="setSearchQuery('')"
                class="px-2 text-gray-500 hover:text-gray-700"
              >
                <dominion-icon-close
                  width="w-3"
                  height="h-3"
                ></dominion-icon-close>
              </button>
            }
          </label>

          <div class="flex flex-1 items-center justify-between gap-3">
            <nav
              aria-label="Filters"
              class="relative -bottom-0.5 flex items-center gap-3"
            >
              @if (isInternalUser) {
                <button
                  type="button"
                  (click)="setShowEmptyResponses(true)"
                  class="flex select-none items-center justify-center border-b-2 px-3 pb-3 text-sm font-medium tracking-wide"
                  [ngClass]="
                    showEmptyResponses
                      ? 'text-dms-green border-b-dms-green'
                      : 'border-b-white/0 text-gray-500 hover:border-b-gray-300'
                  "
                >
                  All
                </button>
                <button
                  type="button"
                  (click)="setShowEmptyResponses(false)"
                  class="flex select-none items-center justify-center border-b-2 px-3 pb-3 text-sm font-medium tracking-wide"
                  [ngClass]="
                    !showEmptyResponses || !isInternalUser
                      ? 'text-dms-green border-b-dms-green'
                      : 'border-b-white/0 text-gray-500 hover:border-b-gray-300'
                  "
                >
                  Answered
                </button>
              } @else {
                <p
                  class="flex select-none items-center py-0.5 pb-3 text-sm font-medium tracking-wide text-gray-500"
                >
                  Answered questions
                </p>
              }
            </nav>

            <button
              type="button"
              (click)="toggleAllGroups()"
              class="text-dms-green hover:bg-dms-green/5 mb-3 flex cursor-pointer select-none items-center justify-center gap-1 rounded-sm py-0.5 pl-1.5 pr-1 text-sm font-medium transition"
            >
              @if (groupsExpanded) {
                <span>Collapse groups</span>
                <dominion-icon-chevrons-down-up
                  size="14"
                ></dominion-icon-chevrons-down-up>
              } @else {
                <span>Expand groups</span>
                <dominion-icon-chevrons-up-down
                  size="14"
                ></dominion-icon-chevrons-up-down>
              }
            </button>
          </div>
        </header>

        <div class="flex flex-col overflow-y-auto">
          @for (group of filteredGroups; track group.groupUrl) {
            <details
              [open]="groupsExpanded"
              [id]="group.groupUrl"
              class="group border-b"
            >
              <summary
                class="sticky top-0 z-10 flex cursor-pointer items-center justify-between gap-6 bg-white p-3 capitalize text-gray-500 hover:bg-gray-50 hover:text-gray-700 [&::-webkit-details-marker]:hidden"
              >
                <div class="flex items-center gap-1.5">
                  <div class="hidden group-open:inline-block">
                    <dominion-icon-chevron-down
                      size="14"
                    ></dominion-icon-chevron-down>
                  </div>
                  <div class="inline-block group-open:hidden">
                    <dominion-icon-chevron-right
                      size="14"
                    ></dominion-icon-chevron-right>
                  </div>
                  <h2
                    class="truncate text-xs font-medium uppercase tracking-wide"
                  >
                    {{ group.groupLabel }}
                  </h2>
                </div>
              </summary>

              <section class="flex flex-col gap-6 px-[34px] pb-6 pt-3">
                @for (question of group.questions; track question.questionKey) {
                  <article
                    class="w-full text-left text-gray-800"
                    [class.opacity-40]="!question.responseText"
                  >
                    <div class="text-sm font-medium">
                      {{ question.promptText }}
                    </div>
                    @if (question.responseText; as responseText) {
                      <p
                        [title]="responseText"
                        class="mt-1 text-sm font-medium italic"
                        [ngClass]="
                          responseText === '[Unknown Response]'
                            ? 'text-amber-600'
                            : 'text-dms-green'
                        "
                      >
                        {{ responseText }}
                      </p>
                    } @else {
                      <p class="mt-1 text-sm font-medium italic">No response</p>
                    }
                  </article>
                }
              </section>
            </details>
          } @empty {
            <div class="flex items-center justify-center gap-1.5 px-3 py-10">
              <p class="text-gray-500">No results found.</p>
              @if (isInternalUser || searchQuery.length > 0) {
                <button
                  (click)="resetFilters()"
                  class="text-dms-green underline underline-offset-2"
                >
                  Clear filters
                </button>
              }
            </div>
          }
        </div>
      </aside>
    }
  </div>
</dialog>
