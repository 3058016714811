import { Component, OnInit, ViewChild } from '@angular/core';
import { IInvitedUserReadBrief } from '@dominion/interfaces';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { RouterLink } from '@angular/router';
import { TableComponent } from '../../../shared/table/table.component';
import { IconGoToComponent } from '../../../icons/icon-go-to.component';
import { UserService } from '../../../user/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchBarComponent } from '../../../shared/search-bar/search-bar.component';
import {
  SortableTableHeaderComponent,
  TableSortOrder,
} from '../../../shared/table/sortable-table-header/sortable-table-header.component';
import { InvitedUserSortField } from 'libs/interfaces/src/lib/constants/sort-fields';
import { NameFromEmailPipe } from '../../../shared/pipes/name-from-email.pipe';

@Component({
  selector: 'dominion-invited-users-table',
  templateUrl: './invited-users-table.component.html',
  styleUrls: ['./invited-users-table.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    RouterLink,
    TableComponent,
    IconGoToComponent,
    SearchBarComponent,
    SortableTableHeaderComponent,
    NameFromEmailPipe,
  ],
})
export class InvitedUsersTableComponent implements OnInit {
  public users: IInvitedUserReadBrief[] = [];
  public currentPage = 1;
  public totalResults = 0;
  public resultsPerPage = 25;
  public isLoadingUsers = false;
  public searchQuery = '';

  sortField: InvitedUserSortField = 'email';
  sortOrder: TableSortOrder = 'ascending';

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.loadUsers();
  }

  get pageCount(): number {
    return Math.ceil(this.totalResults / this.resultsPerPage);
  }

  loadUsers(page = 1, showLoading = true): void {
    if (showLoading) {
      this.isLoadingUsers = true;
    }
    this.userService
      .getInvitedUsers(page, this.searchQuery, this.sortField, this.sortOrder)
      .subscribe({
        next: (result) => {
          this.currentPage = page;
          this.totalResults = result.count;
          this.users = result.users;
          this.isLoadingUsers = false;
        },
        error: (err) => {
          console.error(err);
        },
      });
    setTimeout(() => {
      this.isLoadingUsers = false;
    }, 200);
  }

  onSort(sort: { field: InvitedUserSortField; order: TableSortOrder }) {
    this.sortField = sort.field;
    this.sortOrder = sort.order;
    this.loadUsers(this.currentPage, false);
  }

  nextPage() {
    if (this.currentPage < this.pageCount) {
      this.loadUsers(this.currentPage + 1);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.loadUsers(this.currentPage - 1);
    }
  }

  firstPage() {
    this.loadUsers(1);
  }

  lastPage() {
    this.loadUsers(this.pageCount);
  }

  searchUsers(value: string) {
    this.searchQuery = value;
    this.isLoadingUsers = true;
    this.currentPage = 1;
    this.userService
      .getInvitedUsers(this.currentPage, value, this.sortField, this.sortOrder)
      .subscribe({
        next: (result) => {
          this.totalResults = result.count;
          this.users = result.users;
          this.isLoadingUsers = false;
          this.searchBar.done();
        },
        error: (err: HttpErrorResponse) => {
          this.isLoadingUsers = false;
          this.searchQuery = '';
          console.error(err);
        },
      });
  }
}
