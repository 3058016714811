import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import {
  ICompanyReadExternalBrief,
  IRequestUserPayload,
} from '@dominion/interfaces';
import { CompanyService } from '../company/company.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private user: IRequestUserPayload | undefined;
  public defaultCompany: ICompanyReadExternalBrief;
  private companies: ICompanyReadExternalBrief[] = [];
  private lastNavEvent: NavigationEnd | undefined;

  public companies$: BehaviorSubject<ICompanyReadExternalBrief[] | undefined> =
    new BehaviorSubject<ICompanyReadExternalBrief[] | undefined>(undefined);

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private companyService: CompanyService,
  ) {
    this.authService.user.subscribe({
      next: (user) => {
        this.user = user;
        this.getMyCompanies();
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.lastNavEvent = event;
        this.handleNavEnd(event);
      }
    });
  }

  setDefaultCompany() {
    const childrenOnly = this.companies.filter((c) => {
      return c.childCompanies.length === 0;
    });
    this.defaultCompany = childrenOnly[0];
    if (this.lastNavEvent) {
      this.handleNavEnd(this.lastNavEvent);
      this.lastNavEvent = undefined;
    }
  }

  getMyCompanies() {
    if (this.user && this.user.userType === 'external') {
      this.companyService.getMyCompanies().subscribe({
        next: (companies) => {
          this.companies = companies.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.companies$.next(companies);
          this.setDefaultCompany();
        },
      });
    }
  }

  handleNavEnd(event: NavigationEnd) {
    if (!this.user) {
      return;
    }
    if (this.user.userType === 'internal') {
      this.handleInternalNav(event);
    }
    if (this.user.userType === 'external') {
      this.handleExternalNav(event);
    }
  }

  handleInternalNav(navEvent: NavigationEnd) {
    if (navEvent.urlAfterRedirects === '/core') {
      this.router.navigateByUrl('/core/admin');
    }
  }

  handleExternalNav(navEvent: NavigationEnd) {
    // if just core, route to default company
    if (navEvent.urlAfterRedirects === '/core') {
      if (!this.defaultCompany) {
        return;
      }
      this.router.navigate(['/core', this.defaultCompany._id, 'dashboard']);
    }
  }
}
